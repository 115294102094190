<template>
  <ion-list>
    <ion-item v-if="user.role_id == 3" button href="member/profile"
      >Profile <ion-icon :icon="personCircleOutline"
    /></ion-item>
    <ion-item v-else button href="/doctor/profile"
      >Profile <ion-icon :icon="personCircleOutline"
    /></ion-item>
    <ion-item button @click="logout()"
      >Logout <ion-icon :icon="closeCircleOutline"
    /></ion-item>
  </ion-list>
</template>

<script>
import { personCircleOutline, closeCircleOutline } from "ionicons/icons";

export default {
  name: "popover",
  setup() {
    return {
      personCircleOutline,
      closeCircleOutline,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    logout: function () {
      this.$store.dispatch("Logout");
    },
  },
};
</script>
<style scoped>
ion-icon {
  font-size: 20px;
  padding-left: 10px;
}
</style>

