import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import actions from "./actions";
import mutations from "./mutators";
import getters from "./getters";

import admin from "./modules/admin";
import doctor from "./modules/doctor";
import externalPatient from "./modules/externalPatient";

import consults from "./modules/consults"
import notifications from "./modules/notifications";
import medicalHistory from "./modules/medicalHistory";
import vitals from "./modules/vitals";
import invoices from "./modules/invoices";
import payfast from "./modules/payfast";

const user = JSON.parse(localStorage.getItem("user"));
const state = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

const store = createStore({
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })],
  namespaced: true,
  state: state,
  getters,
  mutations,
  actions,
  modules: {
    admin,
    doctor,
    externalPatient,

    consults,
    notifications,
    medicalHistory,
    vitals,
    invoices,
    payfast,
  }
});


export default store;
