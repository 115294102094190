<template>
  <ion-card-content>
    <ion-row>
      <ion-col size-md="8" size-lg="8">
        <form ref="form" @submit.prevent="submit">
          <p v-if="errors.length" style="background: var(--ion-color-secondary-tint);padding: 10px;border-radius: 9px;margin-bottom: 50px;">
            <b>Please correct the following error(s):</b>
            <ul>
              <li v-for="error in errors" :key="error" style="text-align: left;">{{ error }}</li>
            </ul>
          </p>

          <ion-row style="margin-bottom: 15px">
            <ion-col size-md="12" size-lg="12">
              <ion-button
                size="small"
                type="submit"
                shape="round"
                style="float: right"
                id="patient-save"
                >Save</ion-button
              >
            </ion-col>
          </ion-row>

          <span class="p-float-label">
            <InputText id="name" type="text" required v-model="payload.name" />
            <label for="name">Full Name</label>
          </span>
          <div class="date-picker-div">
            <ion-row>
              <label for="dob" class="date-label">Date of Birth</label>
            </ion-row>
            <ion-row>
             <ion-col size-md="3" size-lg="3">
              <InputText
              id="day"
              type="number"
              required
              v-model="dob.day"
            />
             </ion-col>
             <ion-col size-md="6" size-lg="6">
               <Dropdown v-model="dob.month" :options="months" optionLabel="month" optionValue="key" placeholder="Month"/>
             </ion-col>
             <ion-col size-md="3" size-lg="3">
              <InputText
              id="year"
              type="number"
              required
              v-model="dob.year"
            />
             </ion-col>
            </ion-row>   

          </div>
          <span class="p-float-label">
            <InputText
              id="mobile"
              type="phone"
              required
              v-model="payload.mobile"
            />
            <label for="mobile">Mobile Number</label>
          </span>
          <span class="p-float-label">
            <InputText
              id="email"
              type="email"
              required
              v-model="payload.email"
            />
            <label for="email">Email</label>
          </span>
        </form>
      </ion-col>
    </ion-row>
  </ion-card-content>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonCardContent,
  IonButton
} from "@ionic/vue";

export default {
  name: "External Patient Details",
  components: {
    IonRow,
    IonCol,
    IonCardContent,
    IonButton
  },
  data() {
    return {
      payload: {
        name: null,
        dob: null,
        email: null,
        mobile: null,
      },
      dob: {
        day: null,
        month: null,
        year: null,
      },
      months: [
        { month: "January", key: "01" },
        { month: "February", key: "02" },
        { month: "March", key: "03"},
        { month: "April", key: "04" },
        { month: "May", key: "05"},
        { month: "June", key: "06"},
        { month: "July", key: "07"},
        { month: "August", key: "08"},
        { month: "September", key: "09"},
        { month: "October", key: "10"},
        { month: "November", key: "11"},
        { month: "December", key: "12"}
      ],
      errors: [],
    };
  },
  computed: {

  },
  methods: {
    submit: function () {
      this.errors = [];

      if(!this.dob.day || !this.dob.month || !this.dob.year){
        this.errors.push("Dob required");
      }

      if(this.dob.month == 1 || this.dob.month == 3 || this.dob.month == 5 || this.dob.month == 7 || this.dob.month == 8 || this.dob.month == 10 || this.dob.month ==12){
        if(this.dob.day > 31){
          this.errors.push("Date cannot be bigger then 31 days");
        }
      } else if(this.dob.month == 4 || this.dob.month == 6 || this.dob.month == 9 || this.dob.month == 11){
        if(this.dob.day > 30){
          this.errors.push("Date cannot be bigger then 30 days");
        }
      }else{
        if(this.dob.day > 29){
          this.errors.push("Date cannot be bigger then 29 days");
        }
      }

      var today =  new Date();
      var limit = today.getFullYear() - 100;
      
      if(this.dob.year < limit && this.dob.year > today.getFullYear()){
        this.errors.push("Year is out of date range");
      }

      if(this.dob.year.length < 4 && this.dob.year.length > 4){
        this.errors.push("Year must be 4 characters long");
      }

      this.payload.dob = this.dob.day + "-" + this.dob.month + "-" + this.dob.year;
  
      if (this.errors.length < 1) {
        this.$store.dispatch("ExternalCreatePatient", this.payload);
      }
    },
  },
};
</script>

<style scoped>
</style>