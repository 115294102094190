<template>
  <ion-page>
    <ion-content style="--background: var(--ion-color-light)" scroll="true">
      <ion-header>
        <ion-toolbar class="main-header">
          <img
            src="/assets/icon/WeDoc_Logo_Standalone_Landscape.svg"
            height="26"
          />
        </ion-toolbar>
      </ion-header>

      <ion-card class="main-card">
        <ion-card-header>
          <ion-card-title>Payment Successful</ion-card-title>
        </ion-card-header>

        <ion-card-content>
          <p style="margin-top: 28px; margin-bottom: 7px">
            Please take a screenshot and send it as proof of payment. Expect a call from one of our Doctors shortly.
          </p>

          <br>

          <p><strong>Details</strong> </p>
          <ul style="list-style-type: none;padding: 0;">
            <li>{{consultDetails.full_name}}</li>
            <li>DOB {{ formatDate(consultDetails.dob) }}</li>
            <li>{{consultDetails.email}}</li>
            <li>{{consultDetails.mobile}}</li>
            <li v-if="invoice.paid == 2"><strong>Paid:</strong> {{invoice.promo_name}} </li>
            <li v-if="invoice.paid == 1"><strong>Paid:</strong> Paysoft </li>
            <li v-if="invoice.paid == 0"><strong>Paid:</strong> Not Paid </li>
          </ul>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
} from "@ionic/vue";

export default {
  name: "Payment",
  components: {
    IonCardTitle,
    IonCardHeader,
    IonCardContent,
    IonCard,
    IonHeader,
    IonToolbar,
    IonPage,
    IonContent,
  },
  data() {
    return {
      payload: {
        consult_id: 0,
        external_consult_id: null
      }
    }
  },
  computed: {
    consultDetails() {
      return this.$store.state.externalPatient.externalConsult;
    },
    invoice() {
      return this.$store.state.invoices.invoice;
    },
  },
  mounted() {
     this.payload.external_consult_id = this.consultDetails.id;
    
     console.log(this.invoice);
     this.$store.dispatch("GetInvoice", this.payload).then();
  },
  methods: {
    // 13 Feb 1987
    formatDate(value) {
      var d = new Date(value),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      var date = [day, month, year].join(" ");
      return date;
    },
  }
};
</script>

<style scoped>
ion-card-title {
  color: var(--ion-color-primary);
}

.main-card {
  margin-top: 12px;
}

p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 21px;
}

h1 {
  font-weight: bold;
  font-size: 30px;
  line-height: 45px;
  color: #000000;
  margin-top: 34px;
  margin-bottom: 35px;
}
</style>