import axios from "axios";
import router from "../../router";
let base_url = "https://api.wedoc.co.za";

const state = {
  vitals: [],
};

const getters = {
  getVitals: ({ vitals }) => {
    return vitals;
  },
};
const mutations = {
  STORE_VITALS: (state, payload) => {
    state.vitals = payload;
  },
};
const actions = {
  Vitals: async ({ commit }, payload) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    await axios.get(base_url + "/api/getVitals/" + payload).then(
      (res) => {
        console.log(res.data);
        commit("STORE_VITALS", res.data);
      },
      (error) => {
        console.log("Failed ", error);
      }
    );
  },
  AddVital: async ( _ , payload) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    await axios.post(base_url + "/api/addVital", {
      value: payload.value,
      vital_type_id: payload.vital_type,
      user_id: payload.user_id
    }).then(
      (res) => {
        console.log(res);
        router.push("/member/vitals");
      },
      (error) => {
        console.log("Failed ", error);
      }
    );
  },
  UploadDocument: async ( _ , payload) => {
    await axios
      .get(base_url + '/sanctum/csrf-cookie')
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    await axios.post(base_url + '/api/uploadDocument', {
      name: payload.name,
      document: payload.document,
      user_id: payload.user_id
    }).then(
      (res) => {
        console.log(res);
        router.push('/member/vitals/upload')
      },
      (error) => {
        console.log("Failed ", error);
      }
    );
  },
  DeleteVital: async ( _ , payload) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    await axios.post(base_url + "/api/deleteVital", {
      id: payload.id,
      user_id: payload.user_id
    }).then(
      (res) => {
        console.log(res);
      },
      (error) => {
        console.log("Failed ", error);
      }
    );
  },
  DeleteDocument: async ( _ , payload) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    await axios.post(base_url + "/api/deleteDocument", {
      id: payload.id,
      user_id: payload.user_id
    }).then(
      (res) => {
        console.log(res);
      },
      (error) => {
        console.log("Failed ", error);
      }
    );
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
