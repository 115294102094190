import router from "../../router";
import axios from "axios";
let base_url = "https://api.wedoc.co.za";

const state = {
  consults: [],
  waitingRoom: false,
  consult: [],
  meeting: []
};

const getters = {
  getPreviousConsults: ({ consults }) => {
    return consults;
  },
};

const mutations = {
  STORE_PREVIOUS_CONSULTS: (state, payload) => {
    state.consults = payload;
  },
  STORE_WAITING_ROOM: (state, payload) => {
    state.waitingRoom = payload;
  },
  STORE_CURRENT_CONSULT: (state, payload) => {
    state.consult = payload;
  },
  STORE_CURRENT_MEETING: (state, payload) => {
    state.meeting = payload;
  }
};

const actions = {
  PreviousConsults: async ({ commit }, payload) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    await axios.get(base_url + "/api/getPreviousConsults/" + payload).then(
      (res) => {
        console.log(res.data);
        commit("STORE_PREVIOUS_CONSULTS", res.data);
      },
      (error) => {
        console.log("Failed ", error);
      }
    );
  },
  SendScript: async ({ commit }, payload) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    await axios.post(base_url + "/api/sendScript/" + payload).then(
        (res) => {
          console.log(res.data);
          commit("STORE_CURRENT_CONSULT", []); 
          commit("STORE_CURRENT_MEETING", []); 
        },
        (error) => {
          console.log("Failed ", error);
          commit("STORE_CURRENT_CONSULT", []); 
          commit("STORE_CURRENT_MEETING", []); 
        }
      );  
  },
  StartConsult: async ( _ , payload) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    await axios.post(base_url + '/api/startConsult', {
      user_id: payload.user_id,
      complaint: payload.complaint,
      mobile: payload.mobile,
      image: payload.image
    }).then(
      (res) => {
        console.log(res.data);
        router.replace("/member/consult/waitingroom");        
      },
      (error) => {
        console.log("Failed ", error);
      }
    );
  },
  CancelConsult: async (_ , payload) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    await axios.post(base_url + '/api/cancelConsult', {
      user_id: payload
    }).then(
      (res) => {
        console.log(res.data);
        router.replace("/member/dashboard"); 
      },
      (error) => {
        console.log("Failed ", error);
      }
    );
  },
  ConsultWaiting: async ( { commit }, user_id) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    await axios.get(base_url + '/api/waitingRoomConsult/'+ user_id)
    .then(
      (res) => {
        console.log('test');
        console.log(res.data);
        commit("STORE_WAITING_ROOM", res.data);        
      },
      (error) => {
        console.log("Failed ", error);
      }
    );
  },
  GetMeeting: async ( { commit }, user_id) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    await axios.get(base_url + '/api/getMeeting/'+ user_id )
    .then(
      (res) => {
        console.log(res.data);
        commit("STORE_CURRENT_MEETING", res.data);        
      },
      (error) => {
        console.log("Failed ", error);
      }
    );
  },
  GetConsult: async ( { commit }, user_id) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    await axios.get(base_url + '/api/getConsult/'+ user_id )
    .then(
      (res) => {
        console.log(res.data);
        commit("STORE_CURRENT_CONSULT", res.data);        
      },
      (error) => {
        console.log("Failed ", error);
      }
    );
  },
  AcceptScript: async ( _ , user_id) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    await axios.post(base_url + '/api/confirmMedicalCertificate/'+ user_id )
    .then(
      (res) => {
        console.log(res.data);     
      },
      (error) => {
        console.log("Failed ", error);
      }
    );
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
