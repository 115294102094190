<template>
  <ion-page>
    <ion-toolbar class="main-header">
      <router-link :to="{ path: `/doctor/dashboard` }" class="logo"
        ><img
          src="/assets/icon/WeDoc_Logo_Standalone_Landscape.svg"
          height="26"
      /></router-link>

     <ion-button @click="openPopover" style="float: right;border-radius: 30px;width: auto;">
        <ion-icon :icon="ellipsisHorizontalOutline"/>
      </ion-button>

      <span style="float: right; padding-right: 30px"
        >Welcome back, <strong>Dr. {{ initials }}</strong>
      </span>
    </ion-toolbar>

    <ion-content
      style="--background: var(--ion-color-light); align-items: center"
    >
      <radial-progress-bar
        :diameter="diameter"
        :completed-steps="completedSteps"
        :total-steps="totalSteps"
        :start-color="startColor"
        :stop-color="stopColor"
        :inner-stroke-color="innerStrokeColor"
      >
        <div id="circle">
          <span style="text-align: center; width: 100%">{{ initials }}</span>
        </div>
      </radial-progress-bar>

      <ion-card class="main-card" style="padding-top: 104px">
        <ion-card-header>
          <ion-card-title
            >Your Profile</ion-card-title
          >
          <p class="red-sub-title">
            Update or edit your profile.
          </p>
        </ion-card-header>

        <ion-card-content style="padding-top: 10px;">
            <form ref="form" @submit.prevent="submit">
                <p v-if="errors.length" style="background: var(--ion-color-secondary-tint);padding: 10px;border-radius: 9px;margin-bottom: 50px;">
                    <b>Please correct the following error(s):</b>
                    <ul>
                    <li v-for="error in errors" :key="error" style="text-align: left;">{{ error }}</li>
                    </ul>
                </p>

                <p v-if="successes.length > 0" style="background: #b3ec70;padding: 10px;border-radius: 9px;margin-bottom: 50px;margin-top: -45px;">
                  <ul>
                    <li v-for="success in successes" :key="success.indexOf(success)" style="text-align: left;">{{ success }}</li>
                  </ul>
                </p>

                <span class="p-float-label" >
                    <InputText
                    id="first_name"
                    type="text"
                    v-model="form.first_name"
                    required
                    />
                    <label for="first_name">First Name</label>
                </span>

                <span class="p-float-label">
                    <InputText
                    id="last_name"
                    type="text"
                    v-model="form.last_name"
                    required
                    />
                    <label for="last_name">Last Name</label>
                </span>

                <div class="date-picker-div">
                    <label for="dob" class="date-label">Date of Birth</label>
                    <datepicker id="dob" v-model="form.dob" :upperLimit="today" inputFormat="yyyy/MM/dd" typeable="true" class="date" autocomplete="off"/>
                </div>

                <label for="genders" class="date-label" >Sex</label>
                <Dropdown v-model="user.gender" :options="genders" optionLabel="name" optionValue="code" disabled placeholder="Sex" />

                <span v-if="user.sa_check" class="p-float-label" style="margin-bottom: 8px;">
                    <InputText
                    id="sa_id"
                    type="text"
                    v-model="user.sa_id"
                    disabled
                    />
                    <label for="sa_id" id="sa_id_label">Passport</label>
                </span>

                <span v-else class="p-float-label" style="margin-bottom: 8px;">
                    <InputText
                    id="sa_id"
                    type="text"
                    v-model="user.sa_id"
                    disabled
                    />
                    <label for="sa_id" id="sa_id_label">South African ID Number</label>
                </span>

                <span class="p-float-label" style="margin-top: 25px">
                    <InputText
                    id="email"
                    type="email"
                    v-model="form.email"
                    
                    />
                    <label for="email">Email Address</label>
                </span>
                
                <ion-button size="small" type="submit" shape="round"
                    >Update</ion-button
                >
            </form>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonContent,
  IonPage,
  popoverController,
  IonIcon,
} from "@ionic/vue";
import { ellipsisHorizontalOutline } from "ionicons/icons";
import RadialProgressBar from "vue-radial-progress";
import Datepicker from "vue3-datepicker";
import Popover from "@/components/menu/popover.vue";

export default {
  name: "WeDoc",
  components: {
    IonPage,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonContent,
    RadialProgressBar,
    Datepicker,
    IonIcon,
  },
  setup() {
    return {
      ellipsisHorizontalOutline,
    };
  },
  data() {
    return {
      form: {
        user_id: null,
        first_name: null,
        last_name: null,
        dob: new Date("2000-01-01"),
        gender: null,
        sa_id: null,
        sa_check: 0,
        email: null,
      },
      completedSteps: 20,
      totalSteps: 100,
      diameter: 178,
      strokeLinecap: "round",
      startColor: "#BF0000",
      stopColor: "#BF0000",
      innerStrokeColor: "#D1D1D1",
      errors: [],
      successes: [],
      today: new Date(),
      genders: [
        { name: "Female", code: "f" },
        { name: "Male", code: "m" },
        { name: "Prefer not to say", code: "p" },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    initials() {
      return this.user.first_name.charAt(0) + this.user.last_name.charAt(0);
    },
  },
  async ionViewDidEnter() {
    this.form.user_id = this.user.id;
    this.form.first_name = this.user.first_name;
    this.form.last_name = this.user.last_name;
    this.form.dob = new Date(this.user.dob);
    this.form.gender = this.user.gender;
    this.form.sa_id = this.user.sa_id;
    this.form.sa_check = this.user.sa_check;
    this.form.email = this.user.email;
  },
  methods: {
    async openPopover(ev) {
      const popover = await popoverController.create({
        component: Popover,
        cssClass: "my-custom-class",
        event: ev,
        translucent: true,
      });
      await popover.present();

      const { role } = await popover.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    },
    submit: async function () {
      this.errors = [];

      if (!this.form.first_name) {
        this.errors.push("First Name required.");
      }
      if (!this.form.last_name) {
        this.errors.push("Last Name required.");
      }
      // if (!this.form.gender) {
      //   this.errors.push("Gender required.");
      // }
      if (this.form.sa_check == 1) {
        if (!this.form.sa_id) {
          this.errors.push("Passport Number required.");
        } else if (this.form.sa_id.length < 9) {
          this.errors.push("Passport Number must be a minimum of 9 digits.");
        }
      } else {
        if (!this.form.sa_id) {
          this.errors.push("South African ID Number required.");
        } else if (this.form.sa_id.length != 13) {
          this.errors.push("South African ID Number must be 13 digits.");
        }
      }

      if (!this.form.email) {
        this.errors.push("Email required.");
      }

      if (this.errors && this.errors.length > 0) {
        document.getElementById("page").scrollToTop();
      } else {
        await this.$store
          .dispatch("UpdateProfile", this.form)
          .then((res) => {
            this.successes.push(res.response);
          })
          .catch((error) => {
            this.errors.push(error.error);
          });
      }
    },
  },
};
</script>

<style lang="css" scoped>
ion-toolbar ion-icon {
  font-size: 22px;
  float: right;
  padding-right: 10px;
}

#circle {
  width: 140px;
  height: 140px;
  background: var(--ion-color-tertiary);
  border-radius: 50%;
  color: var(--ion-color-primary);
  display: flex;
  align-items: center;
  font-size: 50px;
}

.radial-progress-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
  top: 73px;
}

.radial-progress-inner {
  width: 125px;
  height: 150px;
}

form {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  color: #777777 !important;
}

.p-inputtext {
  font-family: Poppins;
  height: 43.5px;
  width: 100%;
  color: #777777;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  border: 1px solid #777777;
}

.p-dropdown {
  font-family: Poppins;
  height: 43.5px;
  width: 100%;
  color: #777777;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  border: 1px solid #777777;
  margin-bottom: 27px;
  text-align: left;
}

.p-calendar {
  width: 100%;
  margin-bottom: 27px;
}

.p-datepicker-trigger{
  background-color: white;
  color: #777777;
  border-left: none;
  border-color: #777777;
}

#date-selector {
  border-right: none;
}

.p-float-label {
  margin-bottom: 27px;
}

@media all and (min-width: 769px) {
  ion-card-content{
    padding-left: 40%;
    padding-right: 40%;
    padding-bottom: 15%
  }
}
</style>
