import axios from "axios";
let base_url = "https://api.wedoc.co.za";

const state = {
  promoCodes: [],
  tradingHours: [],
};

const mutations = {
  STORE_PROMO_CODES: (state, payload) => {
    state.promoCodes = payload;
  },
  STORE_TRADING_HOURS: (state, payload) => {
    state.tradingHours = payload;
  },
};

const actions = {
  PromoCodes: async ({ commit }) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    await axios.get(base_url + "/api/admin/promoCodes").then(
      (res) => {
        console.log(res.data);
        commit("STORE_PROMO_CODES", res.data);
      },
      (error) => {
        console.log("Failed ", error);
      }
    );
  },
  TradingHours: async ({ commit }) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    await axios.get(base_url + "/api/admin/tradingHours").then(
      (res) => {
        console.log(res.data);
        commit("STORE_TRADING_HOURS", res.data);
      },
      (error) => {
        console.log("Failed ", error);
      }
    );
  },
  EditTradingHours: async (_, payload) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    return new Promise((resolve, reject) => {
      axios
        .post(base_url + "/api/admin/editTradingHours", {
          start_time: payload.start_time,
          end_time: payload.end_time,
        })
        .then(
          (res) => {
            console.log(res);
            if (res.data) {
              console.log(res.data);
            }
            resolve({ response: res.data });
          },
          (error) => {
            console.log("Failed ", error);
            reject({ error: error.data });
          }
        );
    });
  },
};

export default {
  state,
  mutations,
  actions,
};
