<template>
  <ion-card-content>    
    <ion-row>
        <ion-col size-md="3" size-lg="3"> </ion-col>
        <ion-col size-md="6" size-lg="6">
            <form ref="form" @submit.prevent="submit">
                <ion-row style="margin-bottom: 15px">
                    <ion-col size-md="12" size-lg="12">
                    <ion-button size="small" type="submit" shape="round" style="float:right" id="patient-save">Save</ion-button>
                    </ion-col>
                </ion-row>
                
                <span class="p-float-label">
                    <InputText id="name" type="text"  v-model="payload.name" disabled />
                    <label for="name">Full Name</label>
                </span>
                <div class="date-picker-div">
                    <label for="dob" class="date-label">Date of Birth</label>
                    <datepicker id="dob" v-model="payload.dob" :upperLimit="today" inputFormat="yyyy/MM/dd" typeable="true" required class="date" autocomplete="off"/>
                </div>
                <span class="p-float-label">
                    <InputText id="email" type="email"  v-model="payload.email" disabled/>
                    <label for="email">Email</label>
                </span>
                <span class="p-float-label">
                    <InputText id="mobile" type="mobile"  v-model="payload.mobile" disabled/>
                    <label for="mobile">Mobile</label>
                </span>
            </form>
        </ion-col>
        <ion-col size-md="3" size-lg="3"> </ion-col>
    </ion-row>
  </ion-card-content>
</template>

<script>
import {} from "@ionic/vue";
import Datepicker from "vue3-datepicker";

export default {
  name: "External Consult Patient",
  components: {
    Datepicker,
  },
  data() {
    return {
      payload: {
        user_id: null,
        name: null,
        dob: new Date('2000-01-01'),
        mobile: null,
        email: null
      },
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    consult() {
      return this.$store.state.doctor.externalConsult;
    },
  },
  created() {
    this.payload.name = this.consult.full_name;
    this.payload.dob = new Date(this.consult.dob);
    this.payload.email = this.consult.email;
    this.payload.mobile = this.consult.mobile;
    this.payload.id = this.consult.id;
  },
  methods: {
    submit: function () {
      this.$store.dispatch("ECUpdatePatient", this.payload);
    },
  },
};
</script>

<style scoped>
</style>