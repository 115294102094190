<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { defineComponent } from "vue";
import { Echo } from "laravel-echo-ionic";

window.io = require('socket.io-client');

window.Echo = new Echo({
  broadcaster: "socket.io",
  host: "api.wedoc.co.za:6001",
});

window.Echo.connector.socket.on("connect", function () {
  console.log("CONNECTED");
});

window.Echo.connector.socket.on("reconnecting", function () {
  console.log("CONNECTING");
});

window.Echo.connector.socket.on("disconnect", function () {
  console.log("DISCONNECTED");
});

export default defineComponent({
  name: "WeDoc",
  components: {
    IonApp,
    IonRouterOutlet,
  },
});
</script>