<template>
  <ion-card-content>    
    <p v-if="successes.length > 0" style="background: #b3ec70;padding: 10px;border-radius: 9px;margin-bottom: 50px;margin-top: -45px;">
      <ul>
        <li v-for="success in successes" :key="success.indexOf(success)" style="text-align: left;">{{ success }}</li>
      </ul>
    </p>

    <p v-if="errors.length" style="background: var(--ion-color-secondary-tint);padding: 10px;border-radius: 9px;margin-bottom: 50px;">
            <b>Please correct the following error(s):</b>
            <ul>
              <li v-for="error in errors" :key="error" style="text-align: left;">{{ error }}</li>
            </ul>
          </p>

    <ion-row>
        <ion-col size-md="3" size-lg="3"> </ion-col>
        <ion-col size-md="6" size-lg="6">
            <form ref="form" @submit.prevent="submit">
                <ion-row style="margin-bottom: 15px">
                    <ion-col size-md="12" size-lg="12">
                    <ion-button size="small" type="submit" shape="round" style="float:right" id="patient-save">Save</ion-button>
                    </ion-col>
                </ion-row>
                
                <Dropdown v-model="payload.paid" :options="paid" optionLabel="name" optionValue="code" placeholder="Payment Status" />
                <Dropdown v-model="payload.consult_type" :options="types" optionLabel="name" optionValue="code" placeholder="Consult Type" />
                <Dropdown v-model="payload.promo_percentage" :options="promo_codes" optionLabel="name" optionValue="code" placeholder="Apply Promo Code" />
               
            </form>
        </ion-col>
        <ion-col size-md="3" size-lg="3"> </ion-col>
    </ion-row>
  </ion-card-content>
</template>

<script>
import {} from "@ionic/vue";

export default {
  name: "External Consult Invoice",
  components: {},
  data() {
    return {
      payload: {
        type: "external",
        consult_id: null,
        paid: null,
        consult_type: "telephonic",
        promo_percentage: null,
        promo_description: null,
      },
      paid: [
        { name: "Paid", code: "1" },
        { name: "Not Paid", code: "0" },
        { name: "Promotion", code: "2" },
      ],
      types: [
        { name: "Video Consult", code: "video" },
        { name: "Telephonic Consult", code: "telephonic" },
      ],
      promo_codes: [
        { name: "No Promo Code", code: "0" },
        { name: "LESS 30%", code: "30" },
        { name: "LESS 50%", code: "50" },
        { name: "LESS 100%", code: "100" },
      ],
      successes: [],
      errors: [],
    };
  },
  computed: {
    consult() {
      return this.$store.state.doctor.externalConsult;
    },
    invoice(){
      return this.$store.state.invoices.invoice;
    }
  },
  created() {
    this.payload.consult_id = this.consult.id;
    this.payload.paid = this.invoice.paid;
    this.payload.promo_percentage = this.invoice.promo_percentage;
    this.payload.promo_description = this.invoice.promo_name;    
  },
  methods: {
    submit: async function () {
      this.errors = [];

      if (!this.payload.paid) {
        this.errors.push("Paid or not paid selection is required.");
      }

      if (!this.payload.consult_type) {
        this.errors.push("Consult type selection is required.");
      }

      if (!this.payload.promo_percentage) {
        this.errors.push("Promotion selection is required.");
      }

      if (this.errors && this.errors.length > 0) {
        document.getElementById('page').scrollToTop()
      }else{
        await this.$store
          .dispatch("CreateInvoice", this.payload)
          .then((res) => {
            console.log(res.response);
            this.successes.push(res.response);
            console.log(this.successes);
          })
          .catch((error) => {
            console.log(error.error);
          });
      }
    },
  },
};
</script>

<style scoped>
.p-dropdown {
  font-family: Poppins;
  height: 43.5px;
  width: 100%;
  color: #777777;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  border: 1px solid #777777;
  margin-bottom: 27px;
  text-align: left;
}
</style>