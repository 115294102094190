<template>
  <ion-card-title style="text-align: center">External Consults</ion-card-title>
  <DataTable
    :value="previousExternalConsults"
    responsiveLayout="scroll"
    :paginator="true"
    :rows="10"
    paginatorTemplate="RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink "
    :rowsPerPageOptions="[5, 10, 15, 25, 50, 75]"
    rowsPerPageTemplate="Rows per page"
    currentPageReportTemplate="{first}-{last} of {totalRecords}"
  >
    <Column header="Date" :sortable="true">
      <template #body="{ data }">
        {{ formatDate(data.created_at) }}
      </template>
    </Column>
    <Column header="Patient" :sortable="true">
      <template #body="{ data }">
        <strong>{{ data.full_name }}</strong>
        <br />
        {{ data.email }}
      </template>
    </Column>
    <Column header="Notes">
      <template #body="{ data }">
        <a
          href=""
          class="table-link"
          @click.prevent="
            openViewNotesModal(data.script.hx, data.script.findings)
          "
          ><img
            src="/assets/icon/view.svg"
            width="15"
            style="
              filter: invert(7%) sepia(97%) saturate(7203%) hue-rotate(5deg)
                brightness(100%) contrast(108%);
            "
          />
          VIEW NOTES</a
        >
      </template>
    </Column>

    <Column header="Script">
      <template #body="{ data }">
        <a class="table-link" v-bind:href="data.script_url" target="_blank"
          ><img
            src="/assets/icon/view.svg"
            width="15"
            style="
              filter: invert(7%) sepia(97%) saturate(7203%) hue-rotate(5deg)
                brightness(100%) contrast(108%);
            "
          />
          VIEW SCRIPT</a
        >

        <a
          href=""
          class="table-link"
          @click.prevent="openSendScriptModal(data.id, data.email)"
          ><img
            src="/assets/icon/back.svg"
            width="13"
            style="
              transform: rotate(-180deg);
              filter: invert(7%) sepia(97%) saturate(7203%) hue-rotate(5deg)
                brightness(100%) contrast(108%);
            "
          />SEND TO PATIENT</a
        >
      </template>
    </Column>
    <Column header="Invoice">
      <template #body="{ data }">
        <a class="table-link" v-bind:href="data.invoice_url" target="_blank"
          ><img
            src="/assets/icon/view.svg"
            width="15"
            style="
              filter: invert(7%) sepia(97%) saturate(7203%) hue-rotate(5deg)
                brightness(100%) contrast(108%);
            "
          />
          VIEW INVOICE</a
        >

        <a
          href=""
          class="table-link"
          @click.prevent="openSendInvoiceModal(data.id, data.email)"
          ><img
            src="/assets/icon/back.svg"
            width="13"
            style="
              transform: rotate(-180deg);
              filter: invert(7%) sepia(97%) saturate(7203%) hue-rotate(5deg)
                brightness(100%) contrast(108%);
            "
          />SEND TO PATIENT</a
        >
      </template>
    </Column>
  </DataTable>

  <!-- View Notes Dialog -->
  <Dialog
    header="VIEW NOTES"
    v-model:visible="displayViewNotes"
    :style="{ width: '50vw' }"
    :modal="true"
  >
    <ion-row style="padding-top: 20px">
      <ion-col size-md="6" size-lg="6">
        <span class="p-float-label">
          <Textarea
            :autoResize="true"
            rows="5"
            cols="30"
            style="min-height: 200px"
            disabled
            v-model="notes.hx"
          />
          <label for="hx">History</label>
        </span>
      </ion-col>
      <ion-col size-md="6" size-lg="6">
        <span class="p-float-label">
          <Textarea
            :autoResize="true"
            rows="5"
            cols="30"
            style="min-height: 200px"
            disabled
            v-model="notes.findings"
          />
          <label for="findings">Findings</label>
        </span>
      </ion-col>
    </ion-row>
  </Dialog>

  <!-- Script Dialog -->
  <Dialog
    header="SEND SCRIPT"
    v-model:visible="displaySendScriptModal"
    :style="{ width: '50vw' }"
    :modal="true"
  >
    <form ref="form" @submit.prevent="sendScript()">
      <span class="p-float-label">
        <InputText id="email" type="text" v-model="script.email" required />
        <label for="email">Send Script to</label>
      </span>
      <ion-button size="small" shape="round" type="submit" style="float: right"
        >Send Script</ion-button
      >
    </form>
  </Dialog>

  <!-- Invoice Dialog -->
  <Dialog
    header="SEND INVOICE"
    v-model:visible="displaySendInvoiceModal"
    :style="{ width: '50vw' }"
    :modal="true"
  >
    <form ref="form" @submit.prevent="sendInvoice()">
      <span class="p-float-label">
        <InputText id="email" type="text" v-model="invoice.email" required />
        <label for="email">Send Invoice to</label>
      </span>
      <ion-button size="small" shape="round" type="submit" style="float: right"
        >Send Invoice</ion-button
      >
    </form>
  </Dialog>
</template>

<script>
import { alertController } from "@ionic/vue";

export default {
  components: {},
  data() {
    return {
      displayViewNotes: false,
      displaySendScriptModal: false,
      displaySendInvoiceModal: false,
      serverOutput: [],
      successes: [],
      errors: [],
      notes: {
        hx: "",
        findings: "",
      },
      script: {
        id: "",
        email: "",
      },
      invoice: {
        id: "",
        email: "",
      },
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    previousExternalConsults() {
      return this.$store.state.doctor.externalPreviousConsults;
    },
  },
  created() {},
  methods: {
    formatDate(value) {
      var d = new Date(value),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    openViewNotesModal(hx, findings) {
      this.notes.hx = hx;
      this.notes.findings = findings;
      this.displayViewNotes = true;
    },
    closeViewNotesModal() {
      this.notes.hx = "";
      this.notes.findings = "";
      this.displayViewNotes = false;
    },
    openSendScriptModal(id, email) {
      this.script.id = "";
      this.script.email = "";
      this.script.id = id;
      this.script.email = email;
      this.displaySendScriptModal = true;
    },
    closeSendScriptModal() {
      this.displaySendScriptModal = false;
    },
    openSendInvoiceModal(id, email) {
      this.invoice.id = "";
      this.invoice.email = "";
      this.invoice.id = id;
      this.invoice.email = email;
      this.displaySendInvoiceModal = true;
    },
    closeSendInvoiceModal() {
      this.displaySendInvoiceModal = false;
    },
    addNotes: function () {
      this.$store.dispatch("UpdateScriptData");
    },
    async sendScript() {
      this.$store.dispatch("EmailExternalScript", this.script).then();
      this.closeSendScriptModal();
      const alert = await alertController.create({
        message: `<p style="font-size: 11px;line-height: 16.5px;text-align: center;color: #343434;margin: 0px" >Script sent successfully </p>`,
        buttons: [
          {
            text: "Close",
            role: "cancel",
            handler: () => {
              console.log("cancel");
            },
          },
        ],
      });

      await alert.present();
    },
    async sendInvoice() {
      this.$store.dispatch("EmailExternalInvoice", this.invoice).then();
      this.closeSendInvoiceModal()
      const alert = await alertController.create({
        message: `<p style="font-size: 11px;line-height: 16.5px;text-align: center;color: #343434;margin: 0px" >Invoice sent successfully </p>`,
        buttons: [
          {
            text: "Close",
            role: "cancel",
            handler: () => {
              console.log("cancel");
            },
          },
        ],
      });

      await alert.present();
    },
  },
};
</script>

<style scoped>
ion-card-title {
  color: var(--ion-color-primary);
  margin: 0px !important;
  padding-top: 48px;
}

p {
  font-size: 20px;
  margin-top: 8px;
}

.content {
  margin-left: 10%;
  margin-right: 10%;
}

h1 {
  font-size: 22px !important;
  line-height: 33px !important;
  font-weight: 700 !important;
  color: black;
  padding-top: 21px;
  margin-right: 28px;
}

hr {
  position: absolute;
  width: 76px;
  height: 0px;
  left: 75%;
  top: 46px;
  border: 1px solid #777777;
  transform: rotate(90deg);
}

.table-link {
  font-size: 10px;
  line-height: 15px;
  color: #000000;
  text-decoration-line: underline;
  margin-right: 30px;
}

@media only screen and (max-width: 1450px) {
  h1 {
    font-size: 20px !important;
  }

  span {
    font-size: 15px !important;
  }

  .hr-2 {
    left: -58px !important;
  }

  hr {
    top: 39px !important;
  }
}
</style>