<template>
 <ion-page>
  <ion-content >
      <img src="/assets/icon/WeDoc_Logo_Standalone_Landscape.svg" height="52" />

      <ion-card-header style="text-align: center">
        <ion-card-title style="margin-top: 24.48px; margin-bottom: 8px"
          >WeDoc (Pty) Limited Disclaimer</ion-card-title
        >
      </ion-card-header>

      <ion-card-content>
        <h2>Medical Records Consent</h2>

        <p>I authorize WeDoc (Pty) Ltd  (hereinafter referred to as “WeDoc”) and any of its agents, parent companies, subsidiaries, and/or affiliate entities, to contact any medical provider and to receive direct copies of any and all medical or other records related to medical services. I also authorize WeDoc to release any information regarding medical care which can include without limitation, medical history, symptoms, treatment, examination, results or diagnosis to any medical provider.</p>

        <p>On behalf of myself and or the patient (in the case when the patient is a family member or my minor child), I hereby expressly consent to the disclosure of medical and other records described above to any or all of the following for the purpose specificized:</p>

        <ul>
            <li>The WeDoc Health Services department in South Africa</li>
            <li>Selected persons within WeDoc or third party legal or financial advisors who have need of these records for business or legal purposes related to my medical care</li>
            <li>Any other medical professional or medical provider that a WeDoc health provider feels it appropriate to consult for the purpose of assisting in medical care</li>
            <li>A third party contractor (including, without limitation, any legal or financial advisor and any debt collection service provider) for recovery of costs</li>
            <li>Auditors who need to review the quality of the care through the processes of a clinical audit.</li>
        </ul>

        <h2>Payment</h2>

        <p>I agree to pay any and all expenses incurred on my behalf or on the behalf of the patient (regardless of whether the patient is my family member or minor child) which are related to the medical care provided when using my account on the WeDoc platform. Medical expenses include, but are not limited to, any medical or non-medical service including video consultation, telephonic consultation, messenger chat services, issuing of prescriptions, issuing of medical certificates, issuing of referrals or any other service provided by WeDoc. I agree my obligation to pay these medical expenses is not voided if I or the patient (in the case where the patient is my family member, or my minor child) has medical aid, medical insurance, supplementation insurance, gap insurance or travel insurance. All payments are made on a pre-paid basis which means that these fees must be paid prior to the start of a consultation with a health professional using the WeDoc platform. </p>

        <p>In the event of my failure to pay for any service rendered to me by WeDoc, then I consent to interest being charged at the prime rate of Standard Bank plus 3% on any overdue amount.  In the event of WeDoc requiring the services of an attorney or debt collector company to recover any amount due to it, I agree to pay for all legal costs incurred by WeDoc on an Attorney and client scale.</p>

        <h2>Legal responsibility and liability</h2>

        <p>All health and medical services provided on the WeDoc platform are provided solely for the convenience and benefit of patients who will be charged for such services.   I accept and use medical services at my sole risk and expense without liability or responsibility of WeDoc whatsoever and agree to indemnify WeDoc for all medical expenses or costs incurred on my or a family member, or my minor child’s behalf who is using my account of the WeDoc platform. Doctors, nurses and other medical staff working on the WeDoc platform work directly for me as their patient. Therefore, the course of medical treatment will be determined exclusively by the health professionals using the WeDoc platform and myself.   I understand and accept there is an inherent risk of not having a physical examination.   I make use of the service is at my own risk and I assume full responsibility for all the risks associated. </p>

        <h2>Services</h2>

        <p>WeDoc health professional(s) does not replace the existing primary care physician relationship. WeDoc is not an insurance product nor a prescription fulfillment warehouse. WeDoc health professional(s) reserve the right to deny care for potential misuse of services. WeDoc cannot be held liable, for the user or the health professional providing the service, with lack of mobile data, poor signal, or poor connectivity to the WeDoc platform. WeDoc and the health professional that works on the WeDoc platform will not be held liable for failure to perform if the failure is attributed (Wholly, partially or late), to an event or circumstance beyond their control which affects their performance of this service to be timeous, effective or otherwise. </p>

        <h2>Law and Jurisdiction</h2>

        <p>Any disputes arising between WeDoc and myself shall be subject to the Laws and Courts of the Republic of South Africa.</p>

        <p>I consent to:</p>
        <ul>
            <li>The provision of healthcare services from a health professional contracted to work on the WeDoc platform, with whom I have or will establish a practitioner-patient relationship, or as a new patient, using telehealth, either by telephone, video consultation or similar whilst authorized by the Health Professions Council of South Africa</li>
            <li>The transfer of my personal and medical information to another practitioner or third party for auditing purposes</li>
            <li>Record-keeping of sessions whether it be by text or video recording</li>
            <li>WeDoc using my email and cell number for marketing and sales purposes regarding WeDoc and Contractors who are associated with WeDoc.</li>
        </ul>

        <p>By selecting agree, I agree to all the terms and conditions.</p>

        <hr />

        <div class="auth-links">
          <a href="/login" style="float: left"> Login </a>
          <a href="/register" style="float: right"> Register </a>
        </div>
      </ion-card-content>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonCardTitle,
  IonCardContent,
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Register",
  components: {
    IonPage,
    IonCardTitle,
    IonCardContent
  },
});
</script>

<style scoped>
h2{
    font-weight: bold;
    padding-bottom: 15px;
}

p{
    padding-bottom: 20px;
}

ion-card {
  width: 100%;
  color: black;
  margin: 0px;
  bottom: 0px;
}

ion-card-content{
    padding-left: 10%;
    padding-right: 10%;
}

img {
  width: 100%;
  padding-left: 20%;
  padding-right: 20%;
  margin-top: 6%;
}

a {
  text-decoration: none;
  font-size: 13px;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #777777;
}

.auth-links {
  height: 33px;
}

@media all and (min-width: 769px) {
   ion-card-content{
    padding-left: 20%;
    padding-right: 20%;
    padding-bottom: 15%
  }

  img {
    height: 202px;
    width: 100%;
    padding-left: 43%;
    padding-right: 43%;
  }
}
</style>
