import axios from "axios";
let base_url = "https://api.wedoc.co.za";

const state = {
  notifications: [],
};

const getters = {
  getNotifications: ({ notifications }) => {
    return notifications;
  },
};
const mutations = {
  STORE_NOTIFICATIONS: (state, payload) => {
    state.notifications = payload;
  },
};
const actions = {
  Notifications: async ({ commit }, payload) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    await axios.get(base_url + "/api/getNotifications/" + payload).then(
      (res) => {
        console.log(res.data);
        commit("STORE_NOTIFICATIONS", res.data);
      },
      (error) => {
        console.log("Failed ", error);
      }
    );
  },
  DismissNotifications: async ( _ , payload) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    await axios.post(base_url + "/api/deleteNotifications", {
      id: payload.id,
      selected: payload.selected,
      user_id: payload.user_id
    }).then(
      (res) => {
        console.log(res);
      },
      (error) => {
        console.log("Failed ", error);
      }
    );
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
