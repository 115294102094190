import router from "../../router";
import axios from "axios";
let base_url = "https://api.wedoc.co.za";

const state = {
  consultsWaiting: [],
  consultsSeen: [],
  currentConsult: [],
  scriptData: [],
  continueConsult: [],
  // External Consults
  externalPreviousConsults: [],
  externalConsult: [],
  continueExternalConsult: []
};

const mutations = {
  STORE_WAITING_PATIENTS: (state, payload) => {
    state.consultsWaiting = payload;
  },
  STORE_SEEN_PATIENTS: (state, payload) => {
    state.consultsSeen = payload;
  },
  STORE_CONTINUE_CONSULT: (state, payload) => {
    state.continueConsult = payload;
  },
  STORE_CURRENT_CONSULT: (state, payload) => {
    state.currentConsult = payload;
  },
  STORE_SCRIPT: (state, payload) => {
    state.scriptData = payload;
  },
  // External Consults
  STORE_EXTERNAL_PREVIOUS_CONSULTS: (state, payload) => {
    state.externalPreviousConsults = payload;
  },
  STORE_EXTERNAL_CONSULT: (state, payload) => {
    state.externalConsult = payload;
  },
  STORE_CONTINUE_EXTERNAL_CONSULT: (state, payload) => {
    state.continueexternalConsult = payload;
  },
};

const actions = {
  registerDoctor: async ({ commit }, formData) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    await axios
      .post(base_url + "/api/register/doctor", {
        user_id: formData.user_id,
        degree: formData.degree,
        university: formData.university,
        date: formData.date,
        hpcsa: formData.hpcsa
        // hpcsa_doc: formData.hpcsa_doc,
        // degree_doc: formData.degree_doc,
        // sa_id: formData.sa_id,
        // cv_doc: formData.cv_doc,
        // signature: formData.signature,
      })
      .then(
        (res) => {
          console.log(res);
          if (res.status) {
            console.log(res.data);
            commit("loginSuccess", res.data.data);
            
          } else {
            router.replace("/member/register");
          }
        },
        (error) => {
          console.log("Failed ", error);
          router.replace("/member/register");
        }
      );
  },
  uploadFile: async ({ commit }, formData) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    await axios
      .post(base_url + "/api/register/doctor/upload", {
        user_id: formData.user_id,
        file: formData.file,
        type: formData.type,
      })
      .then(
        (res) => {
          console.log(res);
          if (res.status) {
            console.log(res.data);
            commit("loginSuccess", res.data.data);
          } else {
            router.replace("/member/register");
          }
        },
        (error) => {
          console.log("Failed ", error);
          router.replace("/member/register");
        }
      );
  },
  PatientsWaiting: async ({ commit }) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    await axios.get(base_url + "/api/getPatientsWaiting/").then(
      (res) => {
        console.log(res.data);
        commit("STORE_WAITING_PATIENTS", res.data);
      },
      (error) => {
        console.log("Failed ", error);
      }
    );
  },
  PatientsSeen: async ({ commit }, user_id) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    await axios.get(base_url + "/api/getPatientsSeen/" + user_id).then(
      (res) => {
        console.log(res.data);
        commit("STORE_SEEN_PATIENTS", res.data);
      },
      (error) => {
        console.log("Failed ", error);
      }
    );
  },
  PreviousExternalConsults: async ({ commit }, user_id) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    await axios.get(base_url + "/api/externalConsult/getPatientsSeen/" + user_id).then(
        (res) => {
          console.log(res.data);
          commit("STORE_EXTERNAL_PREVIOUS_CONSULTS", res.data);
        },
        (error) => {
          console.log("Failed ", error);
        }
      );
  },
  inConsult: async ({ commit }, user_id) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    await axios.get(base_url + "/api/inConsult/" + user_id).then(
      (res) => {
        console.log(res.data);
        commit("STORE_CURRENT_CONSULT", res.data.consult[0]);
        commit("STORE_CONTINUE_CONSULT", res.data.consult);
      },
      (error) => {
        console.log("Failed ", error);
      }
    );
  },
  inExternalConsult: async ({ commit }, user_id) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    await axios.get(base_url + "/api/externalConsult/inConsult/" + user_id).then(
        (res) => {
          console.log(res.data);
          commit("STORE_EXTERNAL_CONSULT", res.data.consult[0]);
          commit("STORE_CONTINUE_EXTERNAL_CONSULT", res.data.consult);
        },
        (error) => {
          console.log("Failed ", error);
        }
      );
  },
  UploadInvestigation: async (_, payload) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    await axios
      .post(base_url + "/api/uploadDocument", {
        name: payload.name,
        document: payload.document,
        user_id: payload.user_id,
      })
      .then(
        (res) => {
          console.log(res);
        },
        (error) => {
          console.log("Failed ", error);
        }
      );
  },
  StartDoctorConsult: async ({ commit }, payload) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    await axios
      .post(base_url + "/api/start", {
        user_id: payload.user_id,
        consult_id: payload.consult_id,
        meeting_id: payload.meeting_id,
      })
      .then(
        (res) => {
          commit("STORE_CURRENT_CONSULT", []);
          commit("STORE_SCRIPT", []);
          console.log(res);
          if (res.data.data) {
            commit("STORE_CURRENT_CONSULT", res.data);
            router.replace("/doctor/consult");
          } else {
            router.replace("/doctor/dashboard");
          }
        },
        (error) => {
          console.log("Failed ", error);
          router.replace("/doctor/dashboard");
        }
      );
  },
  UpdateScriptData: async ({ commit }, payload) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    return new Promise((resolve, reject) => {
      axios
        .post(base_url + "/api/setScriptData", {
          consult_id: payload.consult_id,
          hx: payload.hx,
          findings: payload.findings,
          dx: payload.dx,
          icd: payload.icd,
          dx_comments: payload.dx_comments,
          rx: payload.rx,
          rx_comments: payload.rx_comments,
          referral: payload.referral,
          disposition: payload.disposition,
          consult_date: payload.consult_date,
          start_date: payload.start_date,
          end_date: payload.end_date,
          medical_certificate_comments: payload.medical_certificate_comments,
        })
        .then(
          (res) => {
            if (res.data) {
              console.log(res.data.data);
              console.log(res.data.success);
              commit("STORE_SCRIPT", res.data.data);
            }
            resolve({ response: res.data.success });
          },
          (error) => {
            console.log("Failed ", error);
            reject({ error: error.response.data.error });
          }
        );
    });
  },
  SendMedicalCertificate: async (_, meeting_id) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    await axios
      .post(base_url + "/api/sendMedicalCertificate/" + meeting_id)
      .then(
        (res) => {
          if (res.data) {
            console.log(res.data);
          }
        },
        (error) => {
          console.log("Failed ", error);
        }
      );
  },
  SendScript: async (_, payload) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    await axios
      .post(base_url + "/api/sendScript/"+ payload)
      .then(
        (res) => {
          if (res.data) {
            console.log(res.data);
          }
        },
        (error) => {
          console.log("Failed ", error);
        }
      );
  },
  completeScript: async (_, user_id) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    await axios.post(base_url + "/api/completeScript/" + user_id).then(
      (res) => {
        console.log(res.data);
      },
      (error) => {
        console.log("Failed ", error);
      }
    );
  },
  EndConsult: async (_, payload) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    await axios
      .post(base_url + "/api/endConsult", {
        consult_id: payload,
      })
      .then(
        (res) => {
          if (res.data) {
            console.log(res.data);
            router.replace("/doctor/dashboard");
          }
        },
        (error) => {
          console.log("Failed ", error);
        }
      );
  },
  // External Consult
  ECCreatePatient: async ({ commit }, payload) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    return axios
      .post(base_url + "/api/createPatient", {
        user_id: payload.user_id,
        name: payload.name,
        email: payload.email,
        dob: payload.dob
      })
      .then(
        (res) => {
          if (res.data) {
            console.log(res.data);
            commit("STORE_EXTERNAL_CONSULT", res.data);
            router.replace("/doctor/externalconsult/script");
          }
        },
        (error) => {
          console.log("Failed ", error);
        }
      );
  },
  ECUpdatePatient: async ({ commit }, payload) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    return axios
      .post(base_url + "/api/updatePatient", {
        id: payload.id,
        name: payload.name,
        email: payload.email,
        mobile: payload.mobile,
        dob: payload.dob
      })
      .then(
        (res) => {
          if (res.data) {
            console.log(res.data);
            commit("STORE_EXTERNAL_CONSULT", res.data);
            router.replace("/doctor/externalconsult/script");
          }
        },
        (error) => {
          console.log("Failed ", error);
        }
      );
  },
  UpdateExternalScriptData: async ({ commit }, payload) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    return new Promise((resolve, reject) => {
      axios
        .post(base_url + "/api/externalConsult/setScriptData", {
          script_id: payload.script_id,
          hx: payload.hx,
          findings: payload.findings,
          dx: payload.dx,
          icd: payload.icd,
          dx_comments: payload.dx_comments,
          rx: payload.rx,
          rx_comments: payload.rx_comments,
          referral: payload.referral,
          disposition: payload.disposition,
          consult_date: payload.consult_date,
          start_date: payload.start_date,
          end_date: payload.end_date,
          medical_certificate_comments: payload.medical_certificate_comments,
        })
        .then(
          (res) => {
            if (res.data) {
              console.log(res.data.data);
              console.log(res.data.success);
              commit("STORE_SCRIPT", res.data.data);
            }
            resolve({ response: res.data.success });
          },
          (error) => {
            console.log("Failed ", error);
            reject({ error: error.response.data.error });
          }
        );
    });
  },
  SendExternalScript: async (_, consult_id) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    await axios.post(base_url + "/api/externalConsult/sendScript/" + consult_id).then(
      (res) => {
        console.log(res.data);
      },
      (error) => {
        console.log("Failed ", error);
      }
    );
  },
  EmailExternalScript: async (_, payload) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
      console.log(payload);
    await axios
      .post(base_url + "/api/sendExternalScript/", {
        id: payload.id,
        email: payload.email
      })
      .then(
        (res) => {
          if (res.data) {
            console.log(res.data);
          }
        },
        (error) => {
          console.log("Failed ", error);
        }
      );
  },
  EmailExternalInvoice: async (_, payload) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    await axios
      .post(base_url + "/api/sendExternalInvoice/", {
        id: payload.id,
        email: payload.email
      })
      .then(
        (res) => {
          if (res.data) {
            console.log(res.data);
          }
        },
        (error) => {
          console.log("Failed ", error);
        }
      );
  },
  EndExternalConsult: async (_, payload) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    await axios
      .post(base_url + "/api/externalConsult/endConsult", {
        consult_id: payload,
      })
      .then(
        (res) => {
          if (res.data) {
            console.log(res.data);
            router.replace("/doctor/dashboard");
          }
        },
        (error) => {
          console.log("Failed ", error);
        }
      );
  },
  DeleteExternalConsult: async (_, payload) => {
    await axios
    .get(base_url + "/sanctum/csrf-cookie")
    .then((res) => console.log(res))
    .catch((err) => console.log(err));
  await axios
    .post(base_url + "/api/deleteExternalConsult/", {
      id: payload.id
    })
    .then(
      (res) => {
        if (res.data) {
          console.log(res.data);
        }
      },
      (error) => {
        console.log("Failed ", error);
      }
    );
},
};

export default {
  state,
  mutations,
  actions,
};
