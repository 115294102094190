<template>

    <ion-toolbar class="main-header">
      <router-link :to="{ path: `/member/dashboard` }" class="logo"><img src="/assets/icon/WeDoc_Logo_Standalone_Landscape.svg" height="26"></router-link>

      <ion-button @click="openPopover" style="float: right;border-radius: 30px;width: auto;">
        <ion-icon :icon="ellipsisHorizontalOutline"/>
      </ion-button>
    </ion-toolbar>
    
    <ion-tabs >
      <ion-tab-bar slot="bottom">
        <!-- href="/member/medicalhistory" -->
        <ion-tab-button tab="medicalhistory" >
          <img class="icons" src="/assets/icon/previous-consults.svg" height="25">
          <ion-label>Medical History</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="vitals" href="/member/vitals">
          <img class="icons" src="/assets/icon/investigations.svg" height="25">
          <ion-label>Vitals</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="consult" href="/member/consult" >
          <div class="fab-container" style="postion:absolute;">
            <ion-fab vertical="center" horizontal="center" slot="fixed" edge>
              <ion-fab-button >
                <img src="/assets/icon/WeDoc_Logo_Avatar_Standalone_White.svg" height="22">
              </ion-fab-button>
            </ion-fab>
          </div>
        </ion-tab-button>

        <ion-tab-button tab="previousconsults" href="/member/previousconsults">
          <img class="icons" src="/assets/icon/medical-history.svg" height="25">
          <ion-label>Previous Consults</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="notifications" href="/member/notifications">
          <img class="icons" src="/assets/icon/notifications.svg" height="25">
          <ion-label>Notifications</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>

</template>

<script>
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  popoverController
} from "@ionic/vue";
import {
  ellipsisHorizontalOutline,
} from "ionicons/icons";
import Popover from '@/components/menu/popover.vue'

export default {
  name: "Tabs",
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon },
  setup() {
    return {
      ellipsisHorizontalOutline
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    async openPopover(ev){
      const popover = await popoverController
      .create({
        component: Popover,
        cssClass: 'my-custom-class',
        event: ev,
        translucent: true
      })
      await popover.present();

       const { role } = await popover.onDidDismiss();
      console.log('onDidDismiss resolved with role', role);
    },
  },
  mounted() {
    this.$store.dispatch("TradingHours");

    window.Echo.channel(this.user.id);
    window.Echo.connector.socket.on("App\\Events\\MeetingStarted", (data) => {
      console.log(data);
      this.$router.push("/member/consult/waitingroom");
    });
  }
};
</script>

<style lang="css">
ion-toolbar ion-icon {
  font-size: 22px;
  float: right;
  padding-right: 10px;
}

/* .main-card {
  text-align: center;
  color: black;
  margin: 0px;
  bottom: 0px;
  border-top-left-radius: 8%;
  border-top-right-radius: 8%;
  width: -webkit-fill-available;
} */

p {
  color: black;
  font-size: 17px;
  margin: 5px;
}

p.red-sub-title {
  color: var(--ion-color-primary);
  font-weight: bold;
  font-size: 12px;
  margin: 0px;
}

ion-card-title {
  font-weight: bold;
  font-size: 25px;
}

.notification-cards {
  background-color: var(--ion-color-secondary-tint-darker);
  text-align: left;
}

.notification-cards ion-card-header {
  padding: 10px;
}

.notification-cards ion-card-title {
  font-size: 13px;
}

.notification-cards ion-card-content {
  font-size: 10px;
  padding: 0px;
  padding-top: 5px;
}

a.notifications-links {
  text-decoration: none;
  font-weight: bold;
  margin-right: 12px;
  font-size: 8px;
}

ion-progress-bar {
  --background: var(--ion-color-secondary);
  --progress-background: var(--ion-color-primary-tint);
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 50px;
  margin-bottom: 50px;
  width: inherit;
}

ion-tab-button{
  color: var(--ion-color-primary);
  font-size: 9px;
  line-height: 13px;
}

.table-links {
  margin-left: 10px;
  filter: invert(7%) sepia(97%) saturate(7203%) hue-rotate(5deg) brightness(100%) contrast(108%);
}

td{
  font-size: 12px;
  line-height: 18px;
  padding: 14px !important;
}

.p-sortable-column-icon{
  font-size: 10px;
}

.p-paginator-icon{
  font-size: 13px;
}

.p-paginator-current{
  font-size: 12px;
}

th {
  font-size: 12px;
  line-height: 21px;
  font-weight: 500 !important;
  background: var(--ion-color-primary-contrast) !important;
  color: #343434 !important;
  padding: 0px !important;
}

table{
  width: -webkit-fill-available;
  color: #343434;
}

tr {
  border-bottom: 1px solid var(--ion-color-medium-shade);
  height: 46px;
}

.pagination {
    font-size: 12px;
    line-height: 18px;
    text-align: left;
    padding-top: 18px;
}

.rows_per_page_selector{
  height: 17.4px;
  width: 41.26px;
  font-size: 10px;
  padding-left: 2px;
  margin-left: 5px;
  margin-right: 10px;
}

.reorder-1 {
  width: 8px;
  filter: invert(7%) sepia(97%) saturate(7203%) hue-rotate(5deg) brightness(100%) contrast(108%);
  float: right;
  margin-top: 5px;
}

.reorder-2 {
  width: 8px;
  filter: invert(86%) sepia(0%) saturate(0%) hue-rotate(346deg) brightness(94%) contrast(92%);
  float: right;
  margin-top: 5px;
}

@media all and (min-width: 769px) {
  .main-card {
    width: 65%
  }
}

.consult-tab {
    background: var(--ion-color-primary);
    border-radius: 29px;
    width: 64px;
    height: 64px;
}

</style>