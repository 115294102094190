<template>
    <router-link :to="{ path: `/doctor/vitals/upload` }" class="unselected router-button" style="margin-left: 34%;">Uploaded Documents</router-link>

        <ion-card-content>
          <ion-grid>
            <ion-row>
              <ion-col width-100>
                <ion-card
                  class="vitals"
                  style="background: url(/assets/icon/vitals-heart-rate.svg) no-repeat, #cb3333;background-size: 134px;background-position-y: bottom;"
                >
                  <span class="vital-title">Heart Rate</span>
                  <router-link :to="{ path: `/doctor/vitals/heartrate` }" class="vital-link" style="float: right"
                    >VIEW STATS</router-link
                  >
                  
                  <div class="vital-value" style="font-size: 67px;padding-left: 39%;" v-if="vitals.vitals.latest_heart_rate">
                    {{vitals.vitals.latest_heart_rate.value}}<span
                      class="vital-measurement"
                      style="font-size: 20px; line-height: 30px"
                      >bpm</span
                    >

                    <span style="font-size: 12px;line-height: 18px;top: 146px;right: 10px;position: absolute;">{{ formatDate( vitals.vitals.latest_heart_rate.created_at ) }}</span>
                  </div>

                  <div v-else class="no-vitals" style="padding-bottom:40px;">
                    <ion-chip>
                      <ion-label>Nothing Logged</ion-label>
                    </ion-chip>

                    <span style="font-size: 12px;line-height: 18px;top: 154px;right: 10px;position: absolute;color:#000000">No data</span>
                  </div>
                </ion-card>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col width-50>
                <ion-card
                  class="vitals"
                  style="background: url(/assets/icon/vitals-blood-pressure.svg) no-repeat, #eeeeee; color: var(--ion-color-primary);background-size: 100px;background-position-y: bottom;"
                >
                  <span class="vital-title" style="color: #000000"
                    >Blood Pressure</span
                  >
                  <br />
                  <router-link :to="{ path: `/doctor/vitals/bloodpressure` }" class="vital-link" style="color: var(--ion-color-primary)"
                    >VIEW STATS</router-link
                  >
  
                  <div class="vital-value" v-if="vitals.vitals.latest_blood_pressure">
                    {{vitals.vitals.latest_blood_pressure.value}}
                    <span style="font-size: 12px;line-height: 18px;top: 154px;right: 10px;position: absolute;">{{ formatDate( vitals.vitals.latest_blood_pressure.created_at ) }}</span>  
                  </div>

                  <div class="no-vitals" v-else>
                    <ion-chip>
                      <ion-label>Nothing Logged</ion-label>
                    </ion-chip>

                    <span style="font-size: 12px;line-height: 18px;top: 154px;right: 10px;position: absolute;color:#000000">No data</span>
                  </div>

                </ion-card>
              </ion-col>
              <ion-col width-50>
                <ion-card
                  class="vitals"
                  style="background: url(/assets/icon/vitals-temperature.svg) no-repeat, rgba(191, 0, 0, 0.65);background-size: 100px;background-position-y: bottom;"
                >
                  <span class="vital-title">Temperature</span>
                  <br />
                  <router-link :to="{ path: `/doctor/vitals/temperature` }" class="vital-link"
                    >VIEW STATS</router-link
                  >

                  <div class="vital-value" v-if="vitals.vitals.latest_temperature">
                    {{vitals.vitals.latest_temperature.value}}
                    <span style="font-size: 12px;line-height: 18px;top: 154px;right: 10px;position: absolute;">{{ formatDate( vitals.vitals.latest_temperature.created_at ) }}</span>   
                  </div>

                  <div class="no-vitals" v-else>
                    <ion-chip>
                      <ion-label>Nothing Logged</ion-label>
                    </ion-chip>

                    <span style="font-size: 12px;line-height: 18px;top: 154px;right: 10px;position: absolute;color:#000000">No data</span>
                  </div>
                  
                </ion-card>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col width-50>
                <ion-card class="vitals" style="background: url(/assets/icon/vitals-blood-glucose.svg) no-repeat, #cb3333; background-size: 100px;background-position-y: bottom;">
                  <span class="vital-title">Blood Glucose</span>
                  <br />
                  <router-link :to="{ path: `/doctor/vitals/bloodglucose` }" class="vital-link"
                    >VIEW STATS</router-link
                  >

                  <div class="vital-value" v-if="vitals.vitals.latest_blood_glucose">
                    {{vitals.vitals.latest_blood_glucose.value}}
                    <span class="vital-measurement">mmol/L</span>

                    <span style="font-size: 12px;line-height: 18px;top: 154px;right: 10px;position: absolute;">{{ formatDate( vitals.vitals.latest_blood_glucose.created_at ) }}</span>
                  </div>

                  <div class="no-vitals" v-else>
                    <ion-chip>
                      <ion-label>Nothing Logged</ion-label>
                    </ion-chip>

                    <span style="font-size: 12px;line-height: 18px;top: 154px;right: 10px;position: absolute;color:#000000">No data</span>
                  </div>

                </ion-card>
              </ion-col>
              <ion-col width-50>
                <ion-card
                  class="vitals"
                  style="background: url(/assets/icon/vitals-oxygen-saturation.svg) no-repeat, #eeeeee; color: var(--ion-color-primary); background-size: 100px;background-position-y: bottom;"
                >
                  <span class="vital-title" style="color: #000000"
                    >Oxygen Saturation</span
                  >
                  <br />
                  <router-link :to="{ path: `/doctor/vitals/oxygensaturation` }" class="vital-link" style="color: var(--ion-color-primary)"
                    >VIEW STATS</router-link
                  >

                  <div class="vital-value" v-if="vitals.vitals.latest_oxygen_saturation">
                    {{vitals.vitals.latest_oxygen_saturation.value}}
                    <span class="vital-measurement">%</span>

                    <span style="font-size: 12px;line-height: 18px;top: 154px;right: 10px;position: absolute;">{{ formatDate( vitals.vitals.latest_oxygen_saturation.created_at ) }}</span>
                  </div>

                  <div class="no-vitals" v-else>
                    <ion-chip>
                      <ion-label>Nothing Logged</ion-label>
                    </ion-chip>

                    <span style="font-size: 12px;line-height: 18px;top: 154px;right: 10px;position: absolute;color:#000000">No data</span>
                  </div>

                </ion-card>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-content>
</template>

<script>
import {
  IonCard,
  IonCardContent,
  IonChip
} from "@ionic/vue";

export default {
  name: "Vitals",
  components: {
    IonCardContent,
    IonCard,
    IonChip
  },
  computed: {
    patient(){
      return this.$store.state.doctor.currentConsult.patient;
    },
    vitals() {
      return this.$store.state.vitals;
    },
  },
  mounted(){
    this.$store.dispatch("Vitals", this.patient.id).then();
  },
 created() {
    this.$store.dispatch("Vitals", this.patient.id).then();
  },
  methods: {
    formatDate(value) {
      var d = new Date(value),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear(),
        hour = d.getHours(),
        min = d.getMinutes();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      var date = [year, month, day].join("-") + " " + hour+":"+min;
      return date;
    },
  }
};
</script>

<style scoped>
ion-card-title {
  color: var(--ion-color-primary);
}

.main-card {
  margin-top: 12px;
}

p {
  font-size: 16px;
  line-height: 24px;
}

.vitals {
  box-shadow: none;
  color: var(--ion-color-primary-contrast);
  padding-top: 10px;
  padding-left: 13px;
  padding-right: 12px;
}

.vital-title {
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  float: left;
}

.vital-link {
  font-size: 8px;
  line-height: 12px;
  float: left;
  color: var(--ion-color-primary-contrast);
  /* font-weight: 600; */
}

.vital-value {
  font-size: 40px;
  line-height: 60px;
  font-weight: 700;
  padding-top: 40px;
  padding-bottom: 50px;
}

.vital-measurement {
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
}

ion-chip{
  background: #777777;
  border: #777777;
  color: var(--ion-color-primary-contrast);
  float: none;
  font-size: 13px;
}

.no-vitals{
  padding-top: 50px;
  padding-bottom: 60px;
}

ion-button {
  width: auto;
}

</style>