import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import axios from "axios";
import jwt from "jsonwebtoken";

import PrimeVue from "primevue/config";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Textarea from "primevue/textarea";
import FileUpload from "primevue/fileupload";
import Chips from "primevue/chips";
import Dialog from "primevue/dialog";

import { IonicVue } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/core.css";
import "./theme/theme.css";

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(store)
  .use(PrimeVue)
  .mixin({
    methods: {
      getToken: async () => {
        const API_KEY = "3cec4a30-52a7-4ea2-8e02-1c1603b414fb";
        const SECRET_KEY = "eacc1ba81b1817f3f9c4231fa994d622cad155ba8eab925ce718188d2479b417";
        const options = { expiresIn: "10m", algorithm: "HS256" };
        const payload = {
          apikey: API_KEY,
          permissions: ["allow_join"], // Trigger permission.
        };
        const token = await jwt.sign(payload, SECRET_KEY, options);
        return token;
      },
      getMeetingID: async (token) => {
        axios.defaults.headers.common["Authorization"] = token;

        return await axios
          .post("https://api.zujonow.com/v1/meetings")
          .then(
            (res => res.data.meetingId),
            (error) => {
              console.log("Failed ", error);
            }
          );
      },
    },
  });

router.isReady().then(() => {
  app.component("InputText", InputText);
  app.component("Dropdown", Dropdown);
  app.component("DataTable", DataTable);
  app.component("Column", Column);
  app.component("Textarea", Textarea);
  app.component("FileUpload", FileUpload);
  app.component("Chips", Chips);
  app.component("Dialog", Dialog);
  app.mount("#app");
});
