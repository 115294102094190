<template>
  <div class="main-card">
    <ion-card-title>Promo Codes</ion-card-title>
    <p>Manage promo codes for consults.</p>
  </div>
  <div class="content">
    <DataTable
      :value="promoCodes"
      responsiveLayout="scroll"
      :paginator="true"
      :rows="10"
      paginatorTemplate="RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink "
      :rowsPerPageOptions="[5, 10, 15, 25, 50, 75]"
      rowsPerPageTemplate="Rows per page"
      currentPageReportTemplate="{first}-{last} of {totalRecords}"
    >
      <Column header="Promo Code" :sortable="true">
        <template #body="{ data }">
          {{ data.promo_code }}
        </template>
      </Column>
      <Column header="Percentage Off" :sortable="true">
        <template #body="{ data }"> {{ data.percentage_off }} % </template>
      </Column>
      <Column header="Expiry Date" :sortable="true">
        <template #body="{ data }">
          {{ formatDate(data.expiry) }}
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
export default {
  components: {},
  computed: {
    promoCodes() {
      return this.$store.state.admin.promoCodes;
    },
  },
  created() {
    this.reloadData();
  },
  methods: {
    formatDate(value) {
      var d = new Date(value),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    reloadData: function () {
      this.$store.dispatch("PromoCodes");
    },
  },
};
</script>

<style scoped>
ion-card-title {
  color: var(--ion-color-primary);
  margin: 0px !important;
  padding-top: 48px;
}

p {
  font-size: 20px;
  margin-top: 8px;
}

.content {
  margin-left: 10%;
  margin-right: 10%;
}

h1 {
  font-size: 22px !important;
  line-height: 33px !important;
  font-weight: 700 !important;
  color: black;
  padding-top: 21px;
  margin-right: 28px;
}

.main-card {
  margin-bottom: 59px;
}

.table-link {
  font-size: 10px;
  line-height: 15px;
  color: #000000;
  text-decoration-line: underline;
  margin-right: 30px;
}

@media only screen and (max-width: 1450px) {
  h1 {
    font-size: 20px !important;
  }
}
</style>