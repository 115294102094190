import router from "../router";
import axios from "axios";
let base_url = "https://api.wedoc.co.za";

let actions = {
  register: async ({ commit }, formData) => {
    return new Promise((resolve, reject) => {
      axios
        .post(base_url + "/api/register", {
          first_name: formData.first_name,
          last_name: formData.last_name,
          dob: formData.dob,
          gender: formData.gender,
          sa_id: formData.sa_id,
          sa_check: formData.sa_check,
          email: formData.email,
          password: formData.password,
          role_id: formData.role_id,
        })
        .then(
          (res) => {
            console.log(res);
            console.log("status");
            if (res.status) {
              commit("loginSuccess", res.data.data);
              if(res.data.data.email_verified_at){
                if (res.data.data.role_id == 3) {
                  router.replace("/member/dashboard");
                } else if (res.data.data.role_id == 2 || res.data.data.role_id == 1) {
                  router.replace("/doctor-register");
                } else {
                  router.replace("/register");
                }
              }else{
                router.replace("/verify-email");
              }
            } else {
              router.replace("/register");
            }
            resolve({ response: res.data.message });
          },
          (error) => {
            if (error.response.status == 422) {
              if (
                error.response.data.errors.email &&
                error.response.data.errors.password
              ) {
                reject({
                  error:
                    error.response.data.errors.email[0] +
                    " " +
                    error.response.data.errors.password[0],
                });
              } else if (error.response.data.errors.email) {
                reject({ error: error.response.data.errors.email[0] });
              }
              reject({ error: error.response.data.errors.password[0] });
            } else {
              reject({
                error: "Something went wrong, please refresh and try again.",
              });
            }
          }
        );
    });
  },
  emailVerification: async (_, token) => {
    return new Promise((resolve, reject) => {
      axios
        .get(base_url + "/api/account/verify/" + token )
        .then(
          (res) => {
            console.log(res.data)
            resolve({ response: res.data.message });
          },
          (error) => {
            console.log("Failed ", error);
            reject({ error: "We couldn't verify your account details." });
          }
        );
    });
  },
  login: async ({ commit }, formData) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    return new Promise((resolve, reject) => {
      axios
        .post(base_url + "/api/login", {
          email: formData.email,
          password: formData.password,
          remember: formData.remember,
        })
        .then(
          (res) => {
            if (res.errors) {
              console.log(res.errors);
              commit("loginFailure");
              router.replace("/register");
            } else {
              console.log(res.data.data);
              commit("loginSuccess", res.data.data);
              if(res.data.data.email_verified_at){
                if (res.data.data.role_id == 3) {
                  reject({ error: "Please WhatsApp us on 066 561 9612 to start a consult" });
                  // router.replace("/member/dashboard");
                } else if (res.data.data.role_id == 2 || res.data.data.role_id == 1 ) {
                  if (res.data.data.doctor_profile) {
                    if (res.data.data.doctor_profile.signature) {
                      router.replace("/doctor/dashboard");
                    }else {
                      router.replace("/doctor-register");
                    }
                  }else{
                    router.replace("/doctor-register");
                  }
                } else {
                  router.replace("/login");
                }
              } else{
                router.replace("/verify-email");
              }
            }
            resolve({ response: "Success" });
          },
          (error) => {
            commit("loginFailure");
            console.log("Failed ", error);
            console.log(error.response.status);
            if (error.response.status == 422) {
              reject({ error: "We couldn't verify your account details." });
            } else {
              reject({
                error: "Something went wrong, please refresh and try again.",
              });
            }
          }
        );
    });
  },
  updateEmail: async ( { commit }, formData) => {
    return new Promise((resolve, reject) => {
      axios
        .post(base_url + "/api/updateEmail", {
          user_id: formData.user_id,
          email: formData.email
        })
        .then(
          (res) => {
            console.log(res.data)
            commit("updateUser", res.data.data);
            // router.replace("/verify-email");
            resolve({ response: "Successfully updated email and resent." });
          },
          (error) => {
            console.log("Failed ", error);
            reject({ error: "Already verified refresh the page" });
          }
        );
    });
  },
  lostPassword: async (_, formData) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    return new Promise((resolve, reject) => {
      axios
        .post(base_url + "/api/password/email", {
          email: formData.email,
        })
        .then(
          (res) => {
            console.log(res);
            if (!res.data.error) {
              resolve({ response: res.data.success });
            } else {
              reject({ error: res.data.error });
            }
          },
          (error) => {
            if (error.response.status == 422) {
              reject({ error: error.response.data.error });
            } else {
              reject({
                error: "Something went wrong, please refresh and try again.",
              });
            }
          }
        );
    });
  },
  resetPassword: async (_, formData) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    return new Promise((resolve, reject) => {
      axios
        .post(base_url + "/api/password/reset", {
          email: formData.email,
          token: formData.token,
          password: formData.password,
          passwordConfirm: formData.passwordConfirm,
        })
        .then(
          (res) => {
            console.log(res);
            if (!res.data.error) {
              resolve({ response: res.data.success });
            } else {
              reject({ error: res.data.error });
            }
          },
          (error) => {
            if (error.response.status == 422) {
              if (
                error.response.data.errors.email &&
                error.response.data.errors.password
              ) {
                reject({
                  error:
                    error.response.data.errors.email[0] +
                    " " +
                    error.response.data.errors.password[0],
                });
              } else if (error.response.data.errors.email) {
                reject({ error: error.response.data.errors.email[0] });
              }
              reject({ error: error.response.data.errors.password[0] });
            } else {
              reject({
                error: "Something went wrong, please refresh and try again.",
              });
            }
          }
        );
    });
  },
  Logout: async () => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    await axios.post(base_url + "/api/logout").then(
      (res) => {
        console.log(res.data);
        window.location = "/";
        sessionStorage.clear();
      },
      (error) => {
        console.log("Failed ", error);
      }
    );
  },
  UpdateProfile: async ({ commit }, formData) => {
    return new Promise((resolve, reject) => {
      axios
        .post(base_url + "/api/update-profile", {
          user_id: formData.user_id,
          first_name: formData.first_name,
          last_name: formData.last_name,
          dob: formData.dob,
          gender: formData.gender,
          sa_id: formData.sa_id,
          sa_check: formData.sa_check,
          email: formData.email
        })
        .then(
          (res) => {
            console.log(res);
            console.log("status");
            commit("updateUser", res.data.data);
            resolve({ response: "Succesfully updated profile" });
          },
          (error) => {
            if (error.response.status == 422) {
              if (error.response.data.errors.email) {
                reject({ error: error.response.data.errors.email[0] });
              }
            } else {
              reject({
                error: "Something went wrong, please refresh and try again.",
              });
            }
          }
        );
    });
  },
};

export default actions;
