<template>
 <ion-page>
  <ion-content style="text-align: center">
      <img src="/assets/icon/WeDoc_Logo_Standalone_Landscape.svg" height="52" />

      <ion-card-header>
        <ion-card-title style="margin-top: 24.48px; margin-bottom: 8px"
          >Register</ion-card-title
        >
      </ion-card-header>

      <ion-card-content>
        <form ref="form" @submit.prevent="submit">
          <p v-if="errors.length" style="background: var(--ion-color-secondary-tint);padding: 10px;border-radius: 9px;margin-bottom: 50px;">
            <b>Please correct the following error(s):</b>
            <ul>
              <li v-for="error in errors" :key="error" style="text-align: left;">{{ error }}</li>
            </ul>
          </p>

          <span class="p-float-label">
            <InputText
              id="first_name"
              type="text"
              v-model="form.first_name"
              required
            />
            <label for="first_name">First Name</label>
          </span>

          <span class="p-float-label">
            <InputText
              id="last_name"
              type="text"
              v-model="form.last_name"
              required
            />
            <label for="last_name">Last Name</label>
          </span>

          <div class="date-picker-div">
            <label for="dob" class="date-label">Date of Birth</label>
            <datepicker id="dob" v-model="form.dob" :upperLimit="today" inputFormat="yyyy/MM/dd" typeable="true" required class="date" autocomplete="off" />
          </div>

          <Dropdown v-model="form.gender" :options="genders" optionLabel="name" optionValue="code" placeholder="Sex" />

          <span class="p-float-label" style="margin-bottom: 8px;">
            <InputText
              id="sa_id"
              type="text"
              v-model="form.sa_id"
              required
            />
            <label for="sa_id" id="sa_id_label">South African ID Number</label>
          </span>

          <input
            type="checkbox"
            id="checkbox"
            v-model="form.sa_check"
            style="float: left; margin-bottom: 16px"
            @click="saCheck()"
          />
          <label for="checkbox" style="float: left;font-size: 13px;line-height: 15.5px;">
            Not a South African Citizen?</label
          >

          <span class="p-float-label" style="margin-top: 50px">
            <InputText
              id="email"
              type="email"
              v-model="form.email"
              
            />
            <label for="email">Email Address</label>
          </span>

          <span class="p-float-label">
            <InputText
              id="confirmEmail"
              type="email"
              v-model="form.confirmEmail"
              required
            />
            <label for="confirmEmail">Confirm Email Address</label>
          </span>

          <span class="p-float-label">
            <InputText
              id="password"
              type="password"
              v-model="form.password"
              required
            />
            <label for="password">Password</label>
          </span>

          <span class="p-float-label">
            <InputText
              id="confirmPassword"
              type="password"
              v-model="form.confirmPassword"
              required
            />
            <label for="confirmPassword">Confirm Password</label>
          </span>

          <Dropdown v-model="form.role_id" :options="users" optionLabel="name" optionValue="code" placeholder="Register as:" style="margin-bottom: 30px"/>

          <input
            type="checkbox"
            id="checkbox"
            v-model="TandC"
            style="float: left; margin-bottom: 16px"
          />
          <label for="checkbox" style="float: left;font-size: 13px;line-height: 15.5px;">
            I Agree to the <a href="/terms-and-conditions" target="_blank">Terms and Conditions</a></label
          >

          <ion-button size="small" type="submit" shape="round"
            >Register</ion-button
          >
        </form>

        <hr />

        <div class="auth-links">
          <a href="/login" style="float: left"> Login </a>
          <a href="/lost-password" style="float: right"> Lost Password? </a>
        </div>
      </ion-card-content>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonCardTitle,
  IonCardContent,
  IonButton,
  IonContent,
} from "@ionic/vue";
import { defineComponent } from "vue";
import Datepicker from "vue3-datepicker";

export default defineComponent({
  name: "Register",
  components: {
    IonPage,
    IonCardTitle,
    IonCardContent,
    IonButton,
    IonContent,
    Datepicker,
  },
  data() {
    return {
      errors: [],
      form: {
        first_name: null,
        last_name: null,
        dob: new Date('2000-01-01'),
        gender: null,
        sa_id: null,
        sa_check: 0,
        email: null,
        password: null,
        role_id: null,
        confirmPassword: null,
        confirmEmail: null,
      },
      TandC: 0,
      today: new Date(),
      genders: [
        { name: "Female", code: "f" },
        { name: "Male", code: "m" },
        { name: "Prefer not to say", code: "p" },
      ],
      users: [
        { name: "Doctor", code: "2" },
        { name: "Patient", code: "3" },
      ],
    };
  },
  methods: {
    submit: async function () {
      this.errors = [];

      if (!this.form.first_name) {
        this.errors.push("First Name required.");
      }
      if (!this.form.last_name) {
        this.errors.push("Last Name required.");
      }
      if (!this.form.gender) {
        this.errors.push("Gender required.");
      }
      if (!this.form.role_id) {
        this.errors.push("Register as required.");
      }

      if (this.form.sa_check == 1) {
        if (!this.form.sa_id) {
          this.errors.push("Passport Number required.");
        }else if(this.form.sa_id.length < 9){
          this.errors.push("Passport Number must be a minimum of 9 digits.");
        }
      } else {
        if (!this.form.sa_id) {
          this.errors.push("South African ID Number required.");
        } else if (this.form.sa_id.length != 13) {
          this.errors.push("South African ID Number must be 13 digits.");
        }
      }

      if (this.form.email) {
        if (this.form.email != this.form.confirmEmail) {
          this.errors.push("Email and confirm email must match.");
        }
      } else {
        this.errors.push("Email required.");
      }

      if (this.form.password) {
        if (this.form.password != this.form.confirmPassword) {
          this.errors.push("Password and confirm password must match.");
        }else if(this.form.password.length < 8){
          this.errors.push("Password must be at least 8 characters.");
        }
      } else {
        this.errors.push("Password required.");
      }

      if (this.TandC != 1) {
        this.errors.push("You need to agree to the Terms and Conditions.");
      }

      if (this.errors && this.errors.length > 0) {
        document.getElementById('page').scrollToTop()
      }else{
        await this.$store.dispatch("register", this.form).then(
        ).catch(
        error=>{
          this.errors.push(error.error);
        }
        );
      }
    },
    saCheck: function(){
      var checked = document.getElementById('checkbox').checked;

      if(checked){
        document.getElementById('sa_id_label').innerHTML = "Passport Number";
      }else{
        document.getElementById('sa_id_label').innerHTML = "South African ID Number";
      }
    }
  },
});
</script>

<style scoped src="../../theme/auth.css">
</style>
