import router from "../../router";
import axios from "axios";
let base_url = "https://api.wedoc.co.za";

const state = {
  externalConsult: [],
  currentExternalConsult: [],
  waitingExternalPatients: [],
};

const mutations = {
  STORE_EXTERNAL_CONSULT: (state, payload) => {
    state.externalConsult = payload;
  },
  STORE_CURRENT_EXTERNAL_CONSULT: (state, payload) => {
    state.currentExternalConsult = payload;
  },
  STORE_WAITING_EXTERNAL_PATIENTS: (state, payload) => {
    state.waitingExternalPatients = payload;
  }
};

const actions = {
  ExternalCreatePatient: async ({ commit }, payload) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    return axios
      .post(base_url + "/api/createPatient", {
        name: payload.name,
        email: payload.email,
        dob: payload.dob,
        mobile: payload.mobile
      })
      .then(
        (res) => {
          if (res.data) {
            console.log(res.data);
            commit("STORE_EXTERNAL_CONSULT", res.data);
            router.replace("/patient/pay-now");
          }
        },
        (error) => {
          console.log("Failed ", error);
        }
      );
  },
  ExternalPatientsWaiting: async ({ commit }) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    await axios.get(base_url + "/api/getExternalPatientsWaiting/").then(
      (res) => {
        console.log(res.data);
        commit("STORE_WAITING_EXTERNAL_PATIENTS", res.data);
      },
      (error) => {
        console.log("Failed ", error);
      }
    );
  },
  StartExternalConsult: async ({ commit }, payload) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    await axios
      .post(base_url + "/api/start/external", {
        user_id: payload.user_id,
        consult_id: payload.consult_id,
      })
      .then(
        (res) => {
          commit("STORE_CURRENT_EXTERNAL_CONSULT", []);
          commit("STORE_EXTERNAL_CONSULT", res.data);
          console.log("result: ",res);
          if (res.data) {
            commit("STORE_CURRENT_EXTERNAL_CONSULT", res.data);
            router.replace("/doctor/externalconsult/script");
          } else {
            router.replace("/doctor/dashboard");
          }
        },
        (error) => {
          console.log("Failed ", error);
          router.replace("/doctor/dashboard");
        }
      );
  },
};

export default {
  state,
  mutations,
  actions,
};
