<template>
  <ion-page>
    <ion-content style="--background: var(--ion-color-light)" scroll="true">
      <ion-header>
        <ion-toolbar class="main-header">
          <img
            src="/assets/icon/WeDoc_Logo_Standalone_Landscape.svg"
            height="26"
          />
        </ion-toolbar>
      </ion-header>

      <ion-card class="main-card">
        <ion-card-header>
          <ion-card-title>Patient Details</ion-card-title>
        </ion-card-header>

        <ion-card-content>
            <patient></patient>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import Patient from "@/components/externalPatient/patient";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
} from "@ionic/vue";

export default {
  name: "Payment",
  components: {
    IonCardTitle,
    IonCardHeader,
    IonCardContent,
    IonCard,
    IonHeader,
    IonToolbar,
    IonPage,
    IonContent,
    Patient
  },
};
</script>

<style scoped>
ion-card-title {
  color: var(--ion-color-primary);
}

.main-card {
  margin-top: 12px;
}

p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 21px;
}

.option {
  border: 1px solid #c7c7c7;
  height: 73px;
  width: 25%;
  border-radius: 7px;
  margin-right: 23px;
  float: left;
}

form {
  padding-top: 102px;
}

h1 {
  font-weight: bold;
  font-size: 30px;
  line-height: 45px;
  color: #000000;
  margin-top: 34px;
  margin-bottom: 35px;
}
</style>