<template>
  <ion-card
    v-for="consult in openExternalConsult"
    :key="consult"
    class="notification-cards"
    style="margin-bottom: 48px; background: #d1d1d1"
  >
    <ion-card-content>
      <ion-row>
        <ion-col size="1" size-md="1" size-lg="1"> </ion-col>

        <ion-col size="3" size-md="3" size-lg="3" style="padding-left: 29px">
          <h1>
            {{ consult.full_name }}
          </h1>
          {{ consult.email }}
          <hr class="hr-1" />
        </ion-col>

        <ion-col
          size="7"
          size-md="7"
          size-lg="7"
          style="padding-left: 30px; padding-right: 20px"
        >
          <h1 style="display: inline-block">Continue Consult</h1>

          <span
            class="consult-card-data"
            style="
              display: inline-block;
              float: right;
              padding-top: 21px;
              font-size: 22px;
              line-height: 33px;
              font-weight: 600;
            "
            >{{ timer(consult.created_at) }}
          </span>
        </ion-col>

        <ion-col size="1" size-md="1" size-lg="1">
          <hr class="hr-2" style="left: -54%" />
          <a href="" @click.prevent="continueExternalConsult(consult.id)">
            <img
              src="/assets/icon/WeDoc_Logo_Avatar_Standalone_White.svg"
              width="30"
              class="consult-link"
            />
          </a>
        </ion-col>
      </ion-row>
    </ion-card-content>
  </ion-card>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    openExternalConsult() {
      return this.$store.state.doctor.continueExternalConsult;
    },
  },
  created() {},
  methods: {
    timer(created) {
      let currentDateTime = new Date();
      let startDateTime = new Date(created);
      let difference = currentDateTime - startDateTime;
      let time = new Date(difference);
      let days = time.getDate() - 1;
      let hours = time.getHours() - 2;

      if (days > 0) {
        let timer =
          days +
          "day " +
          hours +
          "hour " +
          time.getMinutes() +
          "min " +
          time.getSeconds() +
          "sec";
        return timer;
      } else if (hours > 0) {
        let timer =
          hours +
          "hour " +
          time.getMinutes() +
          "min " +
          time.getSeconds() +
          "sec";
        return timer;
      }

      let timer = time.getMinutes() + "min " + time.getSeconds() + "sec";
      return timer;
    },
    continueExternalConsult() {
      this.$router.replace("/doctor/externalconsult/script");
    },
  },
};
</script>

<style scoped>
p {
  font-size: 20px;
  margin-top: 8px;
}

.content {
  margin-left: 10%;
  margin-right: 10%;
}

h1 {
  font-size: 22px !important;
  line-height: 33px !important;
  font-weight: 700 !important;
  color: black;
  padding-top: 21px;
  margin-right: 28px;
}

.consult-card-data {
  font-size: 18px;
  line-height: 27px;
  color: #777777;
}

hr {
  position: absolute;
  width: 76px;
  height: 0px;
  left: 75%;
  top: 46px;
  border: 1px solid #777777;
  transform: rotate(90deg);
}

.consult-link {
  background: #bf0000;
  padding-top: 23px;
  padding-bottom: 23px;
  width: 70px;
  height: 70px;
  border-radius: 35px;
  margin-top: 15px;
  float: right;
  margin-right: 24px;
}

@media only screen and (max-width: 1450px) {
  h1 {
    font-size: 20px !important;
  }

  span {
    font-size: 15px !important;
  }

  .consult-link {
    padding-top: 16px;
    padding-bottom: 15px;
    width: 50px;
    height: 50px;
  }

  .hr-2 {
    left: -58px !important;
  }

  hr {
    top: 39px !important;
  }
}
</style>