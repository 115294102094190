<template>
  <ion-card-content>
    <div class="consult-data">
      <span v-if="patient.gender == 'f'"
        >({{ age(consult.patient.dob) }}) year old female now presenting with
        ({{ consult.data.complaint }})</span
      >
      <span v-else-if="patient.gender == 'm'"
        >({{ age(consult.patient.dob) }}) year old male now presenting with ({{
          consult.data.complaint
        }})</span
      >
      <span v-else
        >({{ age(consult.patient.dob) }}) year old now presenting with ({{
          consult.data.complaint
        }})</span
      >
    </div>

    <p v-if="successes.length > 0" style="background: #b3ec70;padding: 10px;border-radius: 9px;margin-bottom: 50px;margin-top: -45px;">
      <ul>
        <li v-for="success in successes" :key="success.indexOf(success)" style="text-align: left;">{{ success }}</li>
      </ul>
    </p>

     <a href="" class="router-button" id="referral" style="margin-right: 12px;" @click.prevent="referral()" >Referral</a>
     <a href="" class="router-button unselected" id="disposition" @click.prevent="disposition()">Disposition</a>

    <ion-row>
      <ion-col size-md="3" size-lg="3"> </ion-col>
      <ion-col size-md="6" size-lg="6">
        <div class="referral">
          <p style="margin-top: 33px;margin-bottom: 38px;">
            Please can you review this patient in order to assit with the
            diagnosis hereunder:
          </p>
          <form ref="form" @submit.prevent="submit">
            <ion-row style="margin-bottom: 15px">
              <ion-col size-md="12" size-lg="12">
                <ion-button size="small" type="submit" shape="round" style="float:right">Save</ion-button>
              </ion-col>
            </ion-row>

            <span class="p-float-label">
              <InputText id="dx" type="text" disabled v-model="scriptData.dx" />
              <label for="dx">Dx</label>
            </span>

            <span class="p-float-label">
              <Textarea
                :autoResize="true"
                rows="5"
                cols="30"
                style="min-height: 200px"
                required
                v-model="payload.referral"
              />
              <label for="referral">Referral</label>
            </span>

            
          </form>
        </div>

        <div class="disposition" style="display: none">
          <form  ref="form" @submit.prevent="submit">
            <ion-row style="margin-bottom: 15px">
              <ion-col size-md="12" size-lg="12">
                <ion-button size="small" type="submit" shape="round" style="float:right">Save</ion-button>
              </ion-col>
            </ion-row>

            <span class="p-float-label">
              <Textarea
                :autoResize="true"
                rows="5"
                cols="30"
                style="min-height: 200px;"
                required
                v-model="payload.disposition"
              />
              <label for="disposition">Disposition</label>
            </span>

            
          </form>
        </div>
      </ion-col>
      <ion-col size-md="3" size-lg="3"> </ion-col>
    </ion-row>
  </ion-card-content>
</template>

<script>
import {} from "@ionic/vue";

export default {
  name: "refferal",
  components: {},
  data() {
    return {
      payload: {
        consult_id: null,
        hx: null,
        findings: null,
        dx: null,
        icd: null,
        dx_comments: null,
        rx: null,
        rx_comments: null,
        referral: null,
        disposition: null,
        consult_date: null,
        start_date: null,
        end_date: null,
        medical_certificate_comments: null
      },
      successes: [],
    };
  },
  computed: {
    consult() {
      return this.$store.state.doctor.currentConsult;
    },
    patient() {
      return this.$store.state.doctor.currentConsult.patient;
    },
    scriptData(){
      return this.$store.state.doctor.scriptData;
    }
  },
  created() {
     this.payload.consult_id = this.consult.id;
  },
  methods: {
    age(dob) {
      let currentDate = new Date();
      let birthDate = new Date(dob);
      let difference = currentDate - birthDate;
      let age = Math.floor(difference / 31557600000);
      return age;
    },
    referral(){
      document.getElementById('disposition').classList.add('unselected');
      document.getElementById('referral').classList.remove('unselected');
   
    document.getElementsByClassName('referral')[0].style.display ='block';
    document.getElementsByClassName('disposition')[0].style.display = 'none';
    },
    disposition(){
      document.getElementById('referral').classList.add('unselected');
      document.getElementById('disposition').classList.remove('unselected');

      document.getElementsByClassName('disposition')[0].style.display ='block';
      document.getElementsByClassName('referral')[0].style.display = 'none';
    },
    submit: async function () {
      await this.$store
        .dispatch("UpdateScriptData", this.payload).then(
          res => {
          console.log(res.response);
          this.successes.push(res.response);
        }
        ).catch(
          error => {
          console.log(error.error);
          }
        );
    },
  },
};
</script>

<style scoped>
.consult-data {
  background: #eeeeee;
  border-radius: 5px;
  padding-top: 19px;
  padding-bottom: 19px;
  margin-bottom: 68px;
}
</style>