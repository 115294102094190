<template>
    <div class="main-card">
              <ion-card-title>Trading Hours</ion-card-title>
              <p>Edit trading hours for consults.</p>
            </div>
            <div class="content" style="margin-left: 30%; margin-right: 30%">
                <p v-if="errors.length > 0" style="background: var(--ion-color-secondary-tint);padding: 10px;border-radius: 9px;margin-bottom: 50px;">
              <ul>
                <li v-for="error in errors" :key="error.indexOf(error)" style="text-align: left;">{{ error }}</li>
              </ul>
            </p>

            <p v-if="successes.length > 0" style="background: #b3ec70;padding: 10px;border-radius: 9px;margin-bottom: 50px;">
              <ul>
                <li v-for="success in successes" :key="success.indexOf(success)" style="text-align: left;">{{ success }}</li>
              </ul>
            </p>

              <form ref="form" @submit.prevent="tradingSubmit">
                <ion-row style="margin-bottom: 15px">
                  <ion-col size-md="10" size-lg="10">
                    <ion-button
                      size="small"
                      type="submit"
                      shape="round"
                      style="float: right"
                      >Save</ion-button
                    >
                  </ion-col>
                  <ion-col size-md="2" size-lg="2"> </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size-md="2" size-lg="2"> </ion-col>
                  <ion-col size-md="5" size-lg="5">
                    <p><strong>Start Time:</strong></p>
                  </ion-col>
                  <ion-col size-md="5" size-lg="5">
                    <input type="time" v-model="tradingForm.start_time" />
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size-md="2" size-lg="2"> </ion-col>
                  <ion-col size-md="5" size-lg="5">
                    <p><strong>End Time:</strong></p>
                  </ion-col>
                  <ion-col size-md="5" size-lg="5">
                    <input type="time" v-model="tradingForm.end_time" />
                  </ion-col>
                </ion-row>
              </form>
            </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      tradingForm: {
        start_time: null,
        end_time: null,
      },
      serverOutput: [],
      successes: [],
      errors: [],
    };
  },
  computed: {
    tradingHours() {
      return this.$store.state.admin.tradingHours;
    },
  },
  created() {
    this.reloadData();

    this.tradingForm.start_time = this.tradingHours.start_time;
    this.tradingForm.end_time = this.tradingHours.end_time;
  },
  methods: {    
    reloadData: function () {
      this.$store.dispatch("TradingHours");
    },
    tradingSubmit: async function () {
      await this.$store
        .dispatch("EditTradingHours", this.tradingForm)
        .then((res) => {
          this.successes.push(res.response);
        })
        .catch((error) => {
          this.errors.push(error.error);
        });
    },
  }

};
</script>

<style scoped>
ion-card-title {
  color: var(--ion-color-primary);
  margin: 0px !important;
  padding-top: 48px;
}

p {
  font-size: 20px;
  margin-top: 8px;
}

.content {
  margin-left: 10%;
  margin-right: 10%;
}

h1 {
  font-size: 22px !important;
  line-height: 33px !important;
  font-weight: 700 !important;
  color: black;
  padding-top: 21px;
  margin-right: 28px;
}

.main-card {
  margin-bottom: 59px;
}

@media only screen and (max-width: 1450px) {
  h1 {
    font-size: 20px !important;
  }
}
</style>