<template>
  <ion-page>
    <ion-toolbar class="main-header">
      <router-link :to="{ path: `/doctor/dashboard` }" class="logo"
        ><img
          src="/assets/icon/WeDoc_Logo_Standalone_Landscape.svg"
          height="26"
      /></router-link>

     <ion-button @click="openPopover" style="float: right;border-radius: 30px;width: auto;">
        <ion-icon :icon="ellipsisHorizontalOutline"/>
      </ion-button>

      <span style="float: right; padding-right: 30px"
        >Welcome back, <strong>Dr. {{ initials }}</strong>
      </span>
    </ion-toolbar>

    <ion-content
      style="--background: var(--ion-color-light); align-items: center"
    >
      <ion-row>
        <ion-col size-md="2" size-lg="2">
        </ion-col>
        <ion-col size-md="8" size-lg="8">
          <div class="tabs-component">
            <tabs :options="{ useUrlFragment: false }">
             <tab name="Patient Details">
                <div class="main-card">
                  <ion-card-title>Patient Details</ion-card-title>
                  <patient></patient>
                </div>
              </tab>
            </tabs>
          </div>
        </ion-col>
        <ion-col size-md="2" size-lg="2">
        </ion-col>
      </ion-row>
    </ion-content>
  </ion-page>
</template>

<script>
import Patient from "@/components/doctor/external-consult/patient";

import { IonIcon, IonPage, IonContent, popoverController } from "@ionic/vue";
import { ellipsisHorizontalOutline, checkmarkCircleOutline } from "ionicons/icons";
import { Tabs, Tab } from "vue3-tabs-component";
import Popover from '@/components/menu/popover.vue'

export default {
  name: "ExternalDoctorConsult",
  components: {
    IonIcon,
    IonPage,
    IonContent,
    Tab,
    Tabs,
    Patient,
  },
  setup() {
    return {
      ellipsisHorizontalOutline,
      checkmarkCircleOutline
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    initials() {
      return this.user.first_name.charAt(0) + " " + this.user.last_name;
    },
  },
  methods: {
    async openPopover(ev){
      const popover = await popoverController
      .create({
        component: Popover,
        cssClass: 'my-custom-class',
        event: ev,
        translucent: true
      })
      await popover.present();

       const { role } = await popover.onDidDismiss();
      console.log('onDidDismiss resolved with role', role);
    },
  },
};
</script>

<style lang="css" scoped>
ion-toolbar ion-icon {
  font-size: 22px;
  float: right;
  padding-right: 10px;
}

ion-card-title {
  color: var(--ion-color-primary);
  margin: 0px !important;
  padding-top: 48px;
}

p {
  font-size: 20px;
  margin-top: 8px;
}

.content {
  margin-left: 10%; 
  margin-right: 10%;
}

h1 {
  font-size: 22px !important;
  line-height: 33px !important;
  font-weight: 700 !important;
  color: black;
  padding-top: 21px;
  margin-right: 28px;
}

.consult-card-data {
  font-size: 18px;
  line-height: 27px;
  color: #777777;
}

.consult-link {
  background: #bf0000;
  padding-top: 23px;
  padding-bottom: 23px;
  width: 70px;
  height: 70px;
  border-radius: 35px;
  margin-top: 15px;
  float: right;
  margin-right: 24px;
}

.tabs-component-panel {
  background: white;
}

.main-card {
  margin-bottom: 59px;
  width: 100%;
  margin: 0px;
}

.table-link {
  font-size: 10px;
  line-height: 15px;
  color: #000000;
  text-decoration-line: underline;
  margin-right: 30px;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #000000;
  width: 389px;
}

</style>