<template>
  <ion-page>
    <ion-content style="--background: var(--ion-color-light)">
      <ion-header>
        <ion-toolbar>
          <ion-title>Upload</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-card class="main-card">
        <ion-card-header>
          <router-link :to="{ path: `/doctor/consult/` }" class="logo"
            ><img src="assets/icon/back.svg" width="26" style="float: left"
          /></router-link>

          <div class="header" style="margin-top: 47px">
            <h5>
              Upload a new
              <br />
              medical document
            </h5>
          </div>
        </ion-card-header>

        <ion-card-content>
          <form ref="form" @submit.prevent="submit">
            <span class="p-float-label">
              <InputText id="name" type="text" v-model="form.name" required />
              <label for="name">Name of document</label>
            </span>

            <div class="document-label">
              <label for="document" class="document-label">Add document</label>
            </div>

            <div id="drop-area">
              <div id="upload">
                <p class="drag-text">
                  Drag a document here 
                  <br />
                  or 
                  <br />
                </p>
                <input
                  type="file"
                  id="fileElem"
                  @change="handleFilesSelected"
                />
                <label class="drop-button" for="fileElem">Select from device</label>
              </div>

              <div id="clear">
                <p id="file" class="drag-text"></p>
                <label class="drop-button" @click="clear()">Clear</label>
              </div>
            </div>

            <ion-button size="small" shape="round" type="submit"
              >Add</ion-button
            >
          </form>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonCard,
  IonCardContent,
  IonCardHeader,
} from "@ionic/vue";

export default {
  name: "Upload",
  components: {
    IonContent,
    IonCardHeader,
    IonCardContent,
    IonCard,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonPage,
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  data() {
    return {
      form: {
        document: null,
        name: null,
        user_id: null,
      },
    };
  },
  ionViewDidEnter() {
    this.form.user_id = this.user.id;
  },
  methods: {
    submit: function () {
      console.log(this.form.document);
      this.$store.dispatch("UploadDocument", this.form);
    },
    clear: function () {
      document.getElementById("clear").style.display = 'none';
      document.getElementById("upload").style.display = 'block';
    },
    handleFilesSelected: function (event) {
      let files = event.target.files;
      ([...files]).forEach(this.uploadFile)
    },
    uploadFile: async function(file) {
      this.form.document = await this.toBase64(file);

      document.getElementById("upload").style.display = 'none';
      document.getElementById("clear").style.display = 'block';
      document.getElementById("file").textContent = file['name'];
    },
    async toBase64(file) {
      return await new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }
  },
  mounted() {
    let dropArea = document.getElementById("drop-area");
    ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
      dropArea.addEventListener(eventName, preventDefaults, false);
    });

    function preventDefaults(e) {
      e.preventDefault();
      e.stopPropagation();
    }

    ['dragenter', 'dragover'].forEach(eventName => {
      dropArea.addEventListener(eventName, highlight, false)
    });

    ['dragleave', 'drop'].forEach(eventName => {
      dropArea.addEventListener(eventName, unhighlight, false)
    });

    function highlight() {
      dropArea.classList.add('highlight')
    }

    function unhighlight() {
      dropArea.classList.remove('highlight')
    }

    dropArea.addEventListener('drop', handleDrop, false);
    
    function handleDrop(e) {
      let dt = e.dataTransfer
      let files = dt.files

      handleFiles(files);
    }

    var that = this;
    function handleFiles(files) {
      ([...files]).forEach(that.uploadFile)
    }
  },
};
</script>
<style scoped src="../../../theme/drag&drop.css">
</style>
<style scoped>
h5 {
  font-weight: 700;
  text-align: left;
  font-size: 20px;
  line-height: 30px;
  color: var(--ion-color-primary);
}

.main-card {
  margin-top: 12px;
}

ion-button {
  float: right;
}
</style>