<template>
  <ion-page>
  <ion-content style="text-align: center">
      <img src="/assets/icon/WeDoc_Logo_Standalone_Portrait.svg" height="153" />

      <ion-card-header>
        <ion-card-title style="margin-top: 31.87px; margin-bottom: 27px"
          >Login</ion-card-title
        >
      </ion-card-header>

      <ion-card-content>
        <form ref="form" @submit.prevent="submit">
          <p v-if="errors.length > 0" style="background: var(--ion-color-secondary-tint);padding: 10px;border-radius: 9px;margin-bottom: 50px;">
            <ul>
              <li v-for="error in errors" :key="error.indexOf(error)" style="text-align: left;">{{ error }}</li>
            </ul>
          </p>

          <span class="p-float-label">
            <InputText id="email" type="email" v-model="form.email" required />
            <label for="email">Email Address</label>
          </span>

          <span class="p-float-label">
            <InputText
              id="password"
              type="password"
              v-model="form.password"
              required
            />
            <label for="password">Password</label>
          </span>

          <div class="checkbox">
            <input type="checkbox" v-model="form.remember" /> Remember Me
          </div>

          <ion-button size="small" type="submit" shape="round"
            >Login</ion-button
          >
        </form>
        <hr />

        <div class="auth-links">
          <a href="/register" style="float: left"> Register </a>
          <a href="/lost-password" style="float: right"> Lost Password? </a>
        </div>
      </ion-card-content>
  </ion-content>
  </ion-page>
</template>


<script>
import {
  IonCardTitle,
  IonCardContent,
  IonButton,
  IonCardHeader,
  IonContent,
  IonPage
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Login",
  components: {
    IonCardTitle,
    IonCardContent,
    IonButton,
    IonCardHeader,
    IonContent,
    IonPage
  },
  data() {
    return {
      form: {
        email: null,
        password: null,
        remember: null,
      },
      errors: []
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.status.loggedIn;
    },
  },
  ionViewDidEnter() {
    if (this.loggedIn) {
      let user = this.$store.state.user;
      if(user.email_verified_at){
        if(user.role_id == 3){
          // this.$router.push("/member/dashboard");
        }else{
          this.$router.push("/doctor/dashboard");
        }
      }else{
        this.$router.push("/verify-email");
      }
    }
  },
  methods: {
    submit: async function () {
      await this.$store.dispatch("login", this.form).then(
      ).catch(
        error=>{
          this.errors.push(error.error);
        }
      );
    },
  },
});
</script>

<style scoped src="../../theme/auth.css">
</style>