<template>
  <ion-page>
    <ion-content style="--background: var(--ion-color-light)" scroll="true">
      <ion-header>
        <ion-toolbar class="main-header">
          <img
            src="/assets/icon/WeDoc_Logo_Standalone_Landscape.svg"
            height="26"
          />
        </ion-toolbar>
      </ion-header>

      <ion-card class="main-card">
        <ion-card-header>
          <ion-card-title>Payment</ion-card-title>
        </ion-card-header>

        <ion-card-content>
          <div class="pay">
            <p style="text-align: left; margin-top: 28px; margin-bottom: 7px">
              Payment Options
            </p>
            <!-- <div class="option">
              <img src="/assets/icon/snapscan-logo.svg" width="70">
            </div> -->
            <div class="option selected">
              <img src="/assets/icon/payfast-logo.svg" width="60" />
            </div>

            <div class="paysoft">
              <form ref="form" @submit.prevent="submit">
                <strong style="display: none" id="promo-code-message"
                  >This promo code does not exist</strong
                >

                <span class="p-float-label">
                  <InputText id="promo" type="promo" v-model="payload.code" />
                  <label for="promo">Promo Code</label>
                </span>

                <ion-button
                  style="width: auto"
                  size="small"
                  type="submit"
                  shape="round"
                  >Apply Promo Code</ion-button
                >

                <h1>Total: R{{ myData.amount }}</h1>

                <input
                  type="checkbox"
                  id="checkbox"
                  @click="termsAndConditions()"
                  style="float: left; margin-bottom: 16px"
                  v-model="terms"
                />
                <label
                  for="checkbox"
                  style="float: left; font-size: 13px; line-height: 15.5px;padding-left:10px;margin-bottom:15px"
                >
                  I Agree to the
                  <a href="/terms-and-conditions" target="_blank"
                    >Terms and Conditions</a
                  ></label
                >
                <a style="display:none;"
                  id="pay-button"
                  v-bind:href="
                    'https://www.payfast.co.za/eng/process?cmd=_paynow&amp;receiver=18798171&amp;item_name=Consult&amp;amount=' +
                    myData.amount +
                    '&amp;return_url=https%3A%2F%2Fportal.wedoc.co.za%2Fpatient%2Fpay-now&amp;cancel_url=https%3A%2F%2Fportal.wedoc.co.za%2Fpay-now'
                  "
                  @click.prevent="paysoft('https://www.payfast.co.za/eng/process?cmd=_paynow&amp;receiver=18798171&amp;item_name=Consult&amp;amount=' +
                    myData.amount +
                    '&amp;return_url=https%3A%2F%2Fportal.wedoc.co.za%2Fpatient%2Fpay-now&amp;cancel_url=https%3A%2F%2Fportal.wedoc.co.za%2Fpay-now')"
                  ><img
                    src="https://www.payfast.co.za/images/buttons/light-small-paynow.png"
                    width="165"
                    height="36"
                    alt="Pay"
                    title="Pay Now with PayFast"
                /></a>

                <a style="display:none;margin-top: 70px;"
                  id="complete"
                  v-bind:href="'/patient/pay-now/complete'"
                  class="router-button"
                  >Complete</a>
              </form>
            </div>

            <div class="snapscan" style="hidden"></div>
          </div>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
} from "@ionic/vue";

export default {
  name: "Payment",
  components: {
    IonCardTitle,
    IonCardHeader,
    IonCardContent,
    IonCard,
    IonHeader,
    IonToolbar,
    IonPage,
    IonContent,
  },
  data() {
    return {
      initial_amount: 300,
      myData: {
        merchant_id: "18798171",
        merchant_key: "4w5hl1mvsznq9",
        // Buyer details
        name_first: "",
        name_last: "",
        email_address: "",
        email_confirmation: 1,
        confirmation_address: this.email_address,
        // Transaction Details
        amount: 300,
        item_name: "Consult",
        signature: "",

        return_url: "http://localhost:8100/member/consult/complaint",
        cancel_url: "http://localhost:8100/member/consult",
      },
      payload: {
        code: "",
        id: null
      },
      terms: null
    };
  },
  computed: {
    consultDetails() {
      return this.$store.state.externalPatient.externalConsult;
    },
  },
  async ionViewDidEnter() { 
    this.payload.id = this.consultDetails.id;
  },
  methods: {
    submit: async function () {
      // Check promo code
      document.getElementById("promo-code-message").style.display = "none";
      this.myData.amount = 300;
      await this.$store
        .dispatch("applyPromocode", this.payload)
        .then((res) => {
          let discount = this.initial_amount * (res.response / 100);
          this.myData.amount = this.initial_amount - discount;

          if (!(this.myData.amount > 1) && this.terms == 1 ) {
              document.getElementById("pay-button").style.display = "none";
              document.getElementById("complete").style.display = "block";
          }

          this.complete_payload.paid = 2;
        })
        .catch((error) => {
          document.getElementById("promo-code-message").style.display = "block";
          console.log(error.error);
        });
    },
    termsAndConditions(){
      if(document.getElementById('checkbox').checked){
        if(this.myData.amount > 1){
          document.getElementById("pay-button").style.display = "block";
        }else{
          document.getElementById("complete").style.display = "block";
        }
      } else {
        document.getElementById("pay-button").style.display = "none";
        document.getElementById("complete").style.display = "none";
      }
    },
    paysoft(url){
      this.$store.dispatch("UpdatePaidInvoice", this.payload);
      console.log(url);
      window.location.replace(url);
    }
  },
};
</script>

<style scoped>
ion-card-title {
  color: var(--ion-color-primary);
}

.main-card {
  margin-top: 12px;
}

p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 21px;
}

.option {
  border: 1px solid #c7c7c7;
  height: 73px;
  width: 25%;
  border-radius: 7px;
  margin-right: 23px;
  float: left;
}

.selected {
  border: 2px solid var(--ion-color-primary);
}

form {
  padding-top: 102px;
}

h1 {
  font-weight: bold;
  font-size: 30px;
  line-height: 45px;
  color: #000000;
  margin-top: 34px;
  margin-bottom: 35px;
}
</style>