<template>
  <ion-page>
    <ion-toolbar class="main-header">
      <router-link :to="{ path: `/doctor/dashboard` }" class="logo"
        ><img
          src="/assets/icon/WeDoc_Logo_Standalone_Landscape.svg"
          height="26"
      /></router-link>

      <ion-button
        @click="openPopover"
        style="float: right; border-radius: 30px; width: auto"
      >
        <ion-icon :icon="ellipsisHorizontalOutline" />
      </ion-button>

      <span style="float: right; padding-right: 30px; padding-top: 10px"
        >Welcome back, <strong>Dr. {{ initials }}</strong>
      </span>
    </ion-toolbar>

    <ion-content
      style="--background: var(--ion-color-light); align-items: center"
    >
      <div class="tabs-component">
        <tabs :options="{ useUrlFragment: false }">
          <tab name="Patients Waiting">
            <router-link
              :to="{ path: '/doctor/externalconsult' }"
              class="router-button"
              style="float: right; margin-top: 10px; margin-right: 10px"
              >Start a Consult Now</router-link
            >
            <div class="main-card">
              <ion-card-title>Patients Waiting</ion-card-title>
              <p>
                View patients waiting in the queue. Click on the patient to
                start consult
              </p>
            </div>

            <div class="content" v-if="openConsult">
              <open-consult></open-consult>
            </div>

            <div class="content" v-if="openExternalConsult">
              <open-external-consult></open-external-consult>
            </div>

            <div class="content" v-if="consultsWaiting">
              <waiting-consult></waiting-consult>
            </div>

            <div class="content" v-if="externalConsultsWaiting">
              <waiting-external-consult></waiting-external-consult>
              
            </div>
          </tab>
          <tab name="Patients Seen">
            <div class="main-card">
              <ion-card-title>Patients Seen</ion-card-title>
              <p>View all patients previously seen.</p>
            </div>

            <div class="content">
              <previous-consult></previous-consult>
              
              <previous-external-consult></previous-external-consult>
            </div>
          </tab>

          <!-- Admin -->
          <!-- <tab name="Admin" v-if="user.role_id">
          </tab> -->
          <tab name="Trading Hours" v-if="user.role_id == 1">
            <trading-hours></trading-hours>
          </tab>
          <tab name="Promo Codes" v-if="user.role_id == 1">
            <promo-codes></promo-codes>
          </tab>
        </tabs>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonIcon,
  IonPage,
  IonContent,
  popoverController,
} from "@ionic/vue";
import { ellipsisHorizontalOutline } from "ionicons/icons";
import { Tabs, Tab } from "vue3-tabs-component";
import Popover from "@/components/menu/popover.vue";

// Patients
import openConsult from "@/components/doctor/dashboard/patients/open.vue";
import waitingConsult from "@/components/doctor/dashboard/patients/waiting.vue";
import previousConsult from "@/components/doctor/dashboard/patients/previous.vue";

// External Patients
import openExternalConsult from "@/components/doctor/dashboard/external-patients/open.vue";
import waitingExternalConsult from "@/components/doctor/dashboard/external-patients/waiting.vue";
import previousExternalConsult from "@/components/doctor/dashboard/external-patients/previous.vue";

// Admin
import tradingHours from "@/components/admin/tradingHours/index.vue";
import promoCodes from "@/components/admin/promoCodes/index.vue";

export default {
  name: "DoctorDashboard",
  components: {
    IonIcon,
    IonPage,
    IonContent,
    Tab,
    Tabs,
    openConsult,
    waitingConsult,
    previousConsult,
    openExternalConsult,
    waitingExternalConsult,
    previousExternalConsult,
    tradingHours,
    promoCodes
  },
  setup() {
    return {
      ellipsisHorizontalOutline,
    };
  },
  data() {
    return {
      serverOutput: [],
      successes: [],
      errors: [],
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    initials() {
      return this.user.first_name.charAt(0) + " " + this.user.last_name;
    },
    openConsult() {
      return this.$store.state.doctor.continueConsult;
    },
    openExternalConsult() {
      return this.$store.state.doctor.continueExternalConsult;
    },
    consultsWaiting() {
      return this.$store.state.doctor.consultsWaiting;
    },
    externalConsultsWaiting() {
      return this.$store.state.externalPatient.waitingExternalPatients;
    },
  },
  ionViewDidEnter() {
    this.reloadData();
  },
  methods: {
    async openPopover(ev) {
      const popover = await popoverController.create({
        component: Popover,
        cssClass: "my-custom-class",
        event: ev,
        translucent: true,
      });
      await popover.present();

      const { role } = await popover.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    },
    reloadData: function () {
      // Patients
      this.$store.dispatch("PatientsWaiting").then();
      this.$store.dispatch("inConsult", this.user.id).then();
      this.$store.dispatch("PatientsSeen", this.user.id).then();

      // External Patients
      this.$store.dispatch("ExternalPatientsWaiting").then();
      this.$store.dispatch("inExternalConsult", this.user.id).then();
      this.$store.dispatch("PreviousExternalConsults", this.user.id).then();
    }, 
  },
  mounted() {
    window.Echo.channel("new-consult");
    window.Echo.connector.socket.on("App\\Events\\ConsultAdded", (data) => {
      console.log(data);
      this.reloadData();
    });
  },
};
</script>

<style lang="css" scoped>
ion-toolbar ion-icon {
  font-size: 22px;
  float: right;
  padding-right: 10px;
}

ion-card-title {
  color: var(--ion-color-primary);
  margin: 0px !important;
  padding-top: 48px;
}

p {
  font-size: 20px;
  margin-top: 8px;
}

.content {
  margin-left: 10%;
  margin-right: 10%;
}

h1 {
  font-size: 22px !important;
  line-height: 33px !important;
  font-weight: 700 !important;
  color: black;
  padding-top: 21px;
  margin-right: 28px;
}

.tabs-component-panel {
  background: white;
}

.main-card {
  margin-bottom: 59px;
}

@media only screen and (max-width: 1450px) {
  h1 {
    font-size: 20px !important;
  }

  span {
    font-size: 15px !important;
  }
}
</style>