<template>
  <ion-page>
  <ion-content style="text-align: center">
      <img src="/assets/icon/WeDoc_Logo_Standalone_Portrait.svg" height="153" />

      <ion-card-header>
        <ion-card-title style="margin-top: 31.87px; margin-bottom: 27px"
          >Reset Password</ion-card-title
        >
      </ion-card-header>

      <ion-card-content>
        <form ref="form" @submit.prevent="submit">
          <p v-if="errors.length > 0" style="background: var(--ion-color-secondary-tint);padding: 10px;border-radius: 9px;margin-bottom: 50px;">
            <ul>
              <li v-for="error in errors" :key="error.indexOf(error)" style="text-align: left;">{{ error }}</li>
            </ul>
          </p>

          <p v-if="successes.length > 0" style="background: #b3ec70;padding: 10px;border-radius: 9px;margin-bottom: 50px;">
            <ul>
              <li v-for="success in successes" :key="success.indexOf(success)" style="text-align: left;">{{ success }}</li>
            </ul>
          </p>

          <span class="p-float-label">
            <InputText
              id="password"
              type="password"
              v-model="form.password"
              required
            />
            <label for="password">Password</label>
          </span>

          <span class="p-float-label">
            <InputText
              id="passwordConfirm"
              type="password"
              v-model="form.passwordConfirm"
              required
            />
            <label for="passwordConfirm">Confirm Password</label>
          </span>

          <ion-button size="large" type="submit" shape="round"
            >Reset Password</ion-button
          >
        </form>
        <hr />

        <div class="auth-links">
          <a href="/login" style="float: left"> Login </a>
          <a href="/register" style="float: right"> Register </a>
        </div>
      </ion-card-content>
  </ion-content>
  </ion-page>
</template>


<script>
import {
  IonCardTitle,
  IonCardContent,
  IonButton,
  IonCardHeader,
  IonContent,
  IonPage
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Reset Password",
  components: {
    IonCardTitle,
    IonCardContent,
    IonButton,
    IonCardHeader,
    IonContent,
    IonPage
  },
  data() {
    return {
      form: {
        email: null,
        token: null,
        password: null,
        passwordConfirm: null
      },
      errors: [],
      successes: []
    };
  },
  ionViewDidEnter(){
    this.form.email = decodeURIComponent(this.$route.params.email);
    this.form.token = decodeURIComponent(this.$route.params.token);
  },
  methods: {
    submit: async function () {
      await this.$store.dispatch("resetPassword", this.form).then(
        res=>{
          this.successes.push(res.response);
        }
      ).catch(
        error=>{
          this.errors.push(error.error);
        }
      );
    },
  },
});
</script>

<style scoped src="../../theme/auth.css">
</style>
<style scoped>
    ion-button {
    width: auto;
    }
</style>