<template>
  <ion-card-content>
    <div class="consult-data">
      <span v-if="patient.gender == 'f'"
        >({{ age(consult.patient.dob) }}) year old female now presenting with
        ({{ consult.data.complaint }})</span
      >
      <span v-else-if="patient.gender == 'm'"
        >({{ age(consult.patient.dob) }}) year old male now presenting with ({{
          consult.data.complaint
        }})</span
      >
      <span v-else
        >({{ age(consult.patient.dob) }}) year old now presenting with ({{
          consult.data.complaint
        }})</span
      >
    </div>

    <p v-if="successes.length > 0" style="background: #b3ec70;padding: 10px;border-radius: 9px;margin-bottom: 50px;margin-top: -45px;">
      <ul>
        <li v-for="success in successes" :key="success.indexOf(success)" style="text-align: left;">{{ success }}</li>
      </ul>
    </p>

    <ion-row>
      <ion-col size-md="3" size-lg="3"> </ion-col>
      <ion-col size-md="6" size-lg="6">
        <form ref="form" @submit.prevent="submit">
          <ion-row style="margin-bottom: 15px">
            <ion-col size-md="12" size-lg="12">
              <ion-button size="small" type="submit" shape="round" style="float:right">Save</ion-button>
            </ion-col>
          </ion-row>
          <div style="padding-bottom: 15px;">
            <span class="p-float-label">
              <!-- <Chips v-model="payload.rx">
                <template #chip="slotProps">
                  <div>
                    <span>{{ slotProps.value }} </span>
                  </div>
                </template>
              </Chips> -->
              <Textarea
                :autoResize="true"
                rows="5"
                cols="30"
                style="min-height: 200px"
                v-model="payload.rx"
              />
              <label for="rx">Rx - Add Medication and Dosage</label>
              <!-- <p style="text-align: left; font-size: 12px;color:#495057">Seperate each by pressing enter</p> -->
            </span>
          </div>

          <span class="p-float-label">
            <Textarea
              :autoResize="true"
              rows="5"
              cols="30"
              style="min-height: 200px"
              v-model="payload.rx_comments"
            />
            <label for="rx_comments">Additional Comments</label>
          </span>

          
        </form>
      </ion-col>
      <ion-col size-md="3" size-lg="3"> </ion-col>
    </ion-row>
  </ion-card-content>
</template>

<script>
import {} from "@ionic/vue";

export default {
  name: "rx",
  components: {},
  data() {
    return {
      payload: {
        consult_id: null,
        hx: null,
        findings: null,
        dx: null,
        icd: null,
        dx_comments: null,
        rx: null,
        rx_comments: null,
        referral: null,
        disposition: null,
        consult_date: null,
        start_date: null,
        end_date: null,
        medical_certificate_comments: null
      },
      successes: [],
    };
  },
  computed: {
    consult() {
      return this.$store.state.doctor.currentConsult;
    },
    patient() {
      return this.$store.state.doctor.currentConsult.patient;
    },
  },
  created() {
     this.payload.consult_id = this.consult.id;
     console.log(this.payload);
  },
  methods: {
    age(dob) {
      let currentDate = new Date();
      let birthDate = new Date(dob);
      let difference = currentDate - birthDate;
      let age = Math.floor(difference / 31557600000);
      return age;
    },
    submit: async function () {
      await this.$store
        .dispatch("UpdateScriptData", this.payload).then(
          res => {
          console.log(res.response);
          this.successes.push(res.response);
        }
        ).catch(
          error => {
          console.log(error.error);
          }
        );
    }
  },
};
</script>

<style scoped>
.consult-data {
  background: #eeeeee;
  border-radius: 5px;
  padding-top: 19px;
  padding-bottom: 19px;
  margin-bottom: 68px;
}

.p-chips{
  width: 100%;
}
</style>