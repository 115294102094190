<template>
  <ion-page>
    <ion-content>
      <ion-card style="text-align: center">
        <img
          src="/assets/icon/WeDoc_Logo_Standalone_Landscape.svg"
          height="52"
        />

        <ion-card-header>
          <ion-card-title style="margin-top: 24.48px; margin-bottom: 8px"
            >Register as a Doctor</ion-card-title
          >
        </ion-card-header>

        <ion-card-content>
          <form ref="form" @submit.prevent="submit">
            <span class="p-float-label">
              <InputText
                id="degree"
                type="text"
                v-model="form.degree"
                required
              />
              <label for="degree">Degree</label>
            </span>

            <span class="p-float-label">
              <InputText
                id="university"
                type="text"
                v-model="form.university"
                required
              />
              <label for="univeristy">University</label>
            </span>

            <div class="date-picker-div">
              <label for="date" class="date-label"
                >Date of Completion of Degree</label
              >
              <datepicker
                id="date"
                v-model="form.date"
                typeable="true"
                required
                class="date"
                :upperLimit="today"
                inputFormat="yyyy/MM/dd"
                autocomplete="off"
              />
            </div>

            <span class="p-float-label">
              <InputText id="hpcsa" type="text" v-model="form.hpcsa" required />
              <label for="hpcsa">HPCSA Number</label>
            </span>

            <ion-button size="small" type="submit" shape="round" id="submit"
              >Submit</ion-button
            >
            <div id="file-uploads" style="display: none;">
            <div style="display: inline-block">
              <div class="document-label">
                <label for="document" class="document-label"
                  >Upload the following documents (pdf)</label
                >
              </div>

              <table>
                <tbody>
                  <tr>
                    <td>
                      <label class="file-label">HPCSA Registration</label>
                    </td>
                    <td>
                      <div id="upload-hpcsa">
                        <input
                          type="file"
                          id="fileElem1"
                          accept=".pdf"
                          @change="hpcsa"
                        />
                        <label class="drop-button" for="fileElem1"
                          >UPLOAD</label
                        >
                      </div>

                      <p id="file-hpcsa" class="file-text"></p>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <label class="file-label">Copy of Degree</label>
                    </td>
                    <td>
                      <div id="upload-degree">
                        <input
                          type="file"
                          id="fileElem2"
                          accept=".pdf"
                          @change="degree"
                        />
                        <label class="drop-button" for="fileElem2"
                          >UPLOAD</label
                        >
                      </div>

                      <p id="file-degree" class="file-text"></p>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <label class="file-label">South African ID</label>
                    </td>
                    <td>
                      <div id="upload-saID">
                        <input
                          type="file"
                          id="fileElem3"
                          accept=".pdf"
                          @change="saID"
                        />
                        <label class="drop-button" for="fileElem3"
                          >UPLOAD</label
                        >
                      </div>

                      <p id="file-saID" class="file-text"></p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label class="file-label">Latest CV</label>
                    </td>
                    <td>
                      <div id="upload-cv">
                        <input
                          type="file"
                          id="fileElem4"
                          accept=".pdf"
                          @change="cv"
                        />
                        <label class="drop-button" for="fileElem4"
                          >UPLOAD</label
                        >
                      </div>

                      <p id="file-cv" class="file-text"></p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label class="file-label">Signature</label>
                    </td>
                    <td>
                      <div id="upload-signature">
                        <input
                          type="file"
                          id="fileElem5"
                          accept="image/png, image/jpeg"
                          @change="signature"
                        />
                        <label class="drop-button" for="fileElem5"
                          >UPLOAD</label
                        >
                      </div>

                      <p id="file-signature" class="file-text"></p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <ion-button size="small" shape="round" @click="complete()"
              >Complete</ion-button
            >
            </div>
          </form>

          <hr />

          <div class="auth-links">
            <a href="/login" style="float: left"> Login </a>
            <a href="/" style="float: right"> Lost Password? </a>
          </div>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import router from "../../router";
import {
  IonCard,
  IonCardTitle,
  IonCardContent,
  IonButton,
  IonContent,
  IonPage,
} from "@ionic/vue";
import { defineComponent } from "vue";
import Datepicker from "vue3-datepicker";

export default defineComponent({
  name: "Doctor Register",
  components: {
    IonCard,
    IonCardTitle,
    IonCardContent,
    IonButton,
    IonContent,
    Datepicker,
    IonPage,
  },
  data() {
    return {
      form: {
        user_id: null,
        degree: null,
        university: null,
        date: new Date("2000-01-01"),
        hpcsa: null,
        hpcsa_doc: null,
        degree_doc: null,
        sa_id: null,
        cv_doc: null,
        signature: null,
      },
      fileForm: {
        user_id: null,
        file: null,
        type: null,
      },
      today: new Date(),
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  ionViewDidEnter() {
    this.form.user_id = this.user.id;
    this.fileForm.user_id = this.user.id;

    if(this.user.doctor_profile){
      this.form.degree = this.user.doctor_profile.degree;
      this.form.university = this.user.doctor_profile.university;
      this.form.date = new Date(this.user.doctor_profile.degree_completion_date);
      this.form.hpcsa = this.user.doctor_profile.hpcsa;

      document.getElementById("submit").style.display = "none";
      document.getElementById("file-uploads").style.display = "block";
    }
  },
  methods: {
    submit: function () {
      console.log(this.form);
      this.$store.dispatch("registerDoctor", this.form);
      document.getElementById("submit").style.display = "none";
      document.getElementById("file-uploads").style.display = "block";
    },
    hpcsa: async function (event) {
      let file = event.target.files[0];
      this.form.hpcsa_doc = await this.toBase64(file);
      this.fileForm.file = this.form.hpcsa_doc;
      this.fileForm.type = 'hpcsa_document';

      this.$store.dispatch("uploadFile", this.fileForm);
      document.getElementById("upload-hpcsa").style.display = "none";
      document.getElementById("file-hpcsa").style.display = "block";
      document.getElementById("file-hpcsa").textContent = "UPLOADED";
    },
    degree: async function (event) {
      let file = event.target.files[0];
      this.form.degree_doc = await this.toBase64(file);
      this.fileForm.file = this.form.degree_doc;
      this.fileForm.type = 'degree_document';

      this.$store.dispatch("uploadFile", this.fileForm);
      document.getElementById("upload-degree").style.display = "none";
      document.getElementById("file-degree").style.display = "block";
      document.getElementById("file-degree").textContent = "UPLOADED";
    },
    saID: async function (event) {
      let file = event.target.files[0];
      this.form.sa_id = await this.toBase64(file);
      this.fileForm.file = this.form.sa_id;
      this.fileForm.type = 'sa_id';

      this.$store.dispatch("uploadFile", this.fileForm);
      document.getElementById("upload-saID").style.display = "none";
      document.getElementById("file-saID").style.display = "block";
      document.getElementById("file-saID").textContent = "UPLOADED";
    },
    cv: async function (event) {
      let file = event.target.files[0];
      this.form.cv_doc = await this.toBase64(file);
      this.fileForm.file = this.form.cv_doc;
      this.fileForm.type = 'cv';

      this.$store.dispatch("uploadFile", this.fileForm);
      document.getElementById("upload-cv").style.display = "none";
      document.getElementById("file-cv").style.display = "block";
      document.getElementById("file-cv").textContent = "UPLOADED";
    },
    signature: async function (event) {
      let file = event.target.files[0];
      this.form.signature = await this.toBase64(file);
      this.fileForm.file = this.form.signature;
      this.fileForm.type = 'signature';

      this.$store.dispatch("uploadFile", this.fileForm);
      document.getElementById("upload-signature").style.display = "none";
      document.getElementById("file-signature").style.display = "inline-block";
      document.getElementById("file-signature").textContent = "UPLOADED";
    },
    async toBase64(file) {
      return await new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    complete() {
      if(this.form.signature.length > 0){
        router.replace("/doctor/dashboard");
      }
    },
  },
});
</script>

<style scoped src="../../theme/auth.css">
</style>
<style scoped>
.drop-button {
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 2px;
  padding-bottom: 4px;
  display: inline-block;
  cursor: pointer;
  border-radius: 28px;
  border: 1px solid var(--ion-color-primary);
  font-size: 10px;
  color: var(--ion-color-primary);
}

#fileElem1,
#fileElem2,
#fileElem3,
#fileElem4,
#fileElem5 {
  display: none;
}

.file-text {
  display: none;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 2px;
  padding-bottom: 4px;
  border-radius: 28px;
  border: 1px solid var(--ion-color-primary);
  background: var(--ion-color-primary);
  font-size: 10px;
  color: white;
}

.document-label {
  text-align: left;
  width: 100%;
  margin-bottom: 5px;
  margin-top: 27px;
  font-size: 16px;
  line-height: 24px;
  color: #343434;
}

.file-label {
  color: #343434;
  font-size: 14px;
  line-height: 30px;
  float: left;
}

.p-fileupload {
  float: right;
}

tr {
  border-bottom: none;
  height: auto;
}

td {
  padding: 0px !important;
}
</style>
