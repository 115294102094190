<template>
  <ion-card-content>    
    <p v-if="successes.length > 0" style="background: #b3ec70;padding: 10px;border-radius: 9px;margin-bottom: 50px;margin-top: -45px;">
      <ul>
        <li v-for="success in successes" :key="success.indexOf(success)" style="text-align: left;">{{ success }}</li>
      </ul>
    </p>

    <form ref="form" @submit.prevent="submit">
      <ion-row style="margin-bottom: 15px">
            <ion-col size-md="12" size-lg="12">
              <ion-button size="small" type="submit" shape="round" style="float:right">Save</ion-button>
            </ion-col>
          </ion-row>
      <ion-row>
        <ion-col size-md="6" size-lg="6">
          <span class="p-float-label">
            <Textarea
              :autoResize="true"
              rows="5"
              cols="30"
              style="min-height: 200px"
              required
              v-model="payload.hx"
            />
            <label for="hx">History</label>
          </span>
        </ion-col>
        <ion-col size-md="6" size-lg="6">
          <span class="p-float-label">
            <Textarea
              :autoResize="true"
              rows="5"
              cols="30"
              style="min-height: 200px"
              required
              v-model="payload.findings"
            />
            <label for="findings">Findings</label>
          </span>
        </ion-col>
      </ion-row>
    </form>
  </ion-card-content>
</template>

<script>
import {} from "@ionic/vue";

export default {
  name: "hx",
  components: {},
  data() {
    return {
      payload: {
        script_id: null,
        hx: null,
        findings: null,
        dx: null,
        icd: null,
        dx_comments: null,
        rx: null,
        rx_comments: null,
        referral: null,
        disposition: null,
        consult_date: null,
        start_date: null,
        end_date: null,
        medical_certificate_comments: null
      },
      successes: [],
    };
  },
  computed: {
    consult() {
      return this.$store.state.doctor.externalConsult;
    },
  },
  created() {
     this.payload.script_id = this.consult.script;
  },
  methods: {
    submit: async function () {
      await this.$store
        .dispatch("UpdateExternalScriptData", this.payload).then(
          res => {
          console.log(res.response);
          this.successes.push(res.response);
          console.log(this.successes);
        }
        ).catch(
          error => {
            console.log(error.error);
          }
        );
    }
  },
};
</script>

<style scoped>
.consult-data {
  background: #eeeeee;
  border-radius: 5px;
  padding-top: 19px;
  padding-bottom: 19px;
  margin-bottom: 68px;
}
</style>