<template>
  <ion-card-content>
    <ion-row>
      <ion-col size-md="6" size-lg="6">
        <span class="p-float-label">
          <InputText id="dx" type="text" disabled v-model="scriptData.dx" />
          <label for="dx">Dx (Diagnosis)</label>
        </span>
      </ion-col>
      <ion-col size-md="6" size-lg="6">
        <span class="p-float-label">
          <InputText id="icd" type="text" disabled v-model="scriptData.icd" />
          <label for="icd">ICD Code</label>
        </span>
      </ion-col>
    </ion-row>

    <span class="p-float-label">
      <InputText id="dx_comments" type="text" disabled v-model="scriptData.dx_comments"/>
      <label for="dx_comments">Additional Comments</label>
    </span>

    <span class="p-float-label">
      <Textarea
        :autoResize="true"
        rows="5"
        cols="30"
        style="min-height: 200px"
        disabled
        v-model="scriptData.rx"
      />
      <label for="rx">Rx (Treatment)</label>
    </span>

    <span class="p-float-label" v-if="scriptData.referral">
      <Textarea
        :autoResize="true"
        rows="5"
        cols="30"
        style="min-height: 200px;overflow-y: scroll;"
        disabled
        v-model="scriptData.referral"
      />
      <label for="referral">Referral</label>
    </span>

    <span class="p-float-label" v-if="scriptData.disposition">
      <Textarea
        :autoResize="true"
        rows="5"
        cols="30"
        style="min-height: 200px"
        disabled
        v-model="scriptData.disposition"
      />
      <label for="disposition">Disposition</label>
    </span>

    <p style="margin-bottom:6px;">
      This medical certificate states that <strong style="text-transform: uppercase;">{{ patient.first_name }} {{ patient.last_name }}</strong> had a consult on
       <strong>{{scriptData.consult_date}}</strong>
    </p>
    <p v-if="scriptData.end_date">
      This medical certificate provided is proof of this consult and states that the patient is unfit for duty on the day of <strong>{{scriptData.start_date}}</strong> and will return to duty on the <strong>{{scriptData.end_date}}</strong> 
    </p>

    <span class="p-float-label" style="margin-top:29px">
      <InputText id="medical_certificate_comments" type="text" disabled v-model="scriptData.medical_certificate_comments"/>
      <label for="medical_certificate_comments">Additional Comments</label>
    </span>
    
    <hr />

    <ion-row>
      <ion-col size-md="1" size-lg="1"> </ion-col>
      <ion-col size-md="4" size-lg="4" style="text-align: left">
        <img v-bind:src="user.doctor_profile.signature" height="35">
        <p style="font-size:18px;line-height:27px"><strong>Dr. {{ initials }}</strong></p>
        <p><span>{{ user.doctor_profile.degree }}</span></p>
        <p><span>MP0747122</span></p>
        <p><span>{{ user.email }}</span></p>
        <p><span>072 243 1617</span></p>
      </ion-col>
      <ion-col size-md="4" size-lg="4" style="text-align: right;padding-right: 17px">
        <p><strong>PRNUM0981648</strong></p> 
        <p>4 Belle Constantia Close,</p> 
        <p>Kreupelbosch</p>  
        <p>Cape Town</p>
        <p>7945</p>
      </ion-col>
      <ion-col size-md="3" size-lg="3">
        <hr class="hr-vertical" />
        <img
          src="/assets/icon/WeDoc_Logo_Standalone_Portrait.svg"
          height="100"
        />
      </ion-col>
    </ion-row>
  </ion-card-content>
</template>

<script>
import {} from "@ionic/vue";

export default {
  name: "script",
  components: {},
  computed: {
    user() {
      return this.$store.state.user;
    },
    initials() {
      return this.user.first_name.charAt(0) + " " + this.user.last_name;
    },
    consult() {
      return this.$store.state.doctor.currentConsult;
    },
    patient() {
      return this.$store.state.doctor.currentConsult.patient;
    },
    scriptData(){
      return this.$store.state.doctor.scriptData;
    }
  },
  watch: {
    scriptData: function(){
      document.getElementById('send-script-a').style.display = "block";
      document.getElementById('end-consult-a').style.display = "none";
      document.getElementById('script-check').style.display = "none";
    }
  },
  created() {},
  methods: {
    age(dob) {
      let currentDate = new Date();
      let birthDate = new Date(dob);
      let difference = currentDate - birthDate;
      let age = Math.floor(difference / 31557600000);
      return age;
    },
  },
};
</script>

<style scoped>
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #000000;
}

.hr-vertical {
  border: 0;
  border-top: 1px solid #000000;
  transform: rotate(90deg);
  position: absolute;
  width: 87px;
  height: 0px;
  top: 28px;
  left: -46px;
}
</style>