<template>
  <ion-page>
  <ion-content style="text-align: center">
      <img src="/assets/icon/WeDoc_Logo_Standalone_Portrait.svg" height="153" />

      <ion-card-header>
        <ion-card-title style="margin-top: 31.87px; margin-bottom: 27px"
          >Verify email</ion-card-title
        >
      </ion-card-header>

      <ion-card-content>
        <p v-if="errors.length > 0" style="background: var(--ion-color-secondary-tint);padding: 10px;border-radius: 9px;margin-bottom: 50px;">
          <ul>
            <li v-for="error in errors" :key="error.indexOf(error)" style="text-align: left;">{{ error }}</li>
          </ul>
        </p>

        <p v-if="successes.length > 0" style="background: #b3ec70;padding: 10px;border-radius: 9px;margin-bottom: 50px;">
          <ul>
            <li v-for="success in successes" :key="success.indexOf(success)" style="text-align: left;">{{ success }}</li>
          </ul>
        </p>

        <p>We sent an email to <strong>{{user.email}}</strong> </p>
        <br>
        <p>Click on the link in the email to verify your email. If you entered your email incorrectly please change your email below.</p>
        
        <hr>
        <br>
        <form ref="form" @submit.prevent="submit">
          <span class="p-float-label">
            <InputText id="email" type="email" v-model="form.email" required />
            <label for="email">Email Address</label>
          </span>
          <ion-button size="large" type="submit" shape="round"
            >Update</ion-button
          >
        </form>
        <hr>
      </ion-card-content>
  </ion-content>
  </ion-page>
</template>

<script>
import {
  IonCardTitle,
  IonCardContent,
  IonButton,
  IonCardHeader,
  IonContent,
  IonPage,
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Verify Email",
  components: {
    IonCardTitle,
    IonCardContent,
    IonButton,
    IonCardHeader,
    IonContent,
    IonPage,
  },
  data() {
    return {
      form: {
        user_id: null,
        email: null,
      },
      errors: [],
      successes: [],
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.status.loggedIn;
    },
    user() {
      return this.$store.state.user;
    },
  },
  ionViewDidEnter() {
    this.form.user_id = this.user.id;
    if (this.loggedIn) {
      let user = this.$store.state.user;
      if (user.email_verified_at) {
        if (user.role_id == 3) {
          this.$router.push("/member/dashboard");
        } else {
          this.$router.push("/doctor/dashboard");
        }
      } else {
        this.$router.push("/verify-email");
      }
    }
  },
  methods: {
    submit: async function () {
      await this.$store
        .dispatch("updateEmail", this.form)
        .then((res) => {
          console.log(res);
          this.successes.push(res.response);
        })
        .catch((error) => {
          this.errors.push(error.error);
        });
    },
  },
});
</script>

<style scoped src="../../theme/auth.css">
</style>