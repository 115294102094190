<template>
  <ion-page>
    <ion-toolbar class="main-header">
      <router-link :to="{ path: `/doctor/dashboard` }" class="logo"
        ><img
          src="/assets/icon/WeDoc_Logo_Standalone_Landscape.svg"
          height="26"
      /></router-link>

      <ion-button
        @click="openPopover"
        style="float: right; border-radius: 30px; width: auto"
      >
        <ion-icon :icon="ellipsisHorizontalOutline" />
      </ion-button>

      <span style="float: right; padding-right: 30px"
        >Welcome back, <strong>Dr. {{ initials }}</strong>
      </span>
    </ion-toolbar>

    <ion-content
      style="--background: var(--ion-color-light); align-items: center"
    >
      <ion-row>
        <ion-col size-md="2" size-lg="2"> </ion-col>
        <ion-col size-md="8" size-lg="8">
          <div class="tabs-component">
            <tabs :options="{ useUrlFragment: false }">
              <tab name="Patient Details">
                <div class="main-card">
                  <ion-card-title>Patient Details</ion-card-title>
                  <patient></patient>
                </div>
              </tab>
              <tab name="Hx and Findings">
                <div class="main-card">
                  <ion-card-title>Hx and Findings</ion-card-title>
                  <p><strong>Patient:</strong> {{ consult.full_name }}</p>
                  <span style="font-size: 15px">{{ consult.mobile }}</span>
                  <hx></hx>
                </div>
              </tab>
              <tab name="Rx">
                <div class="main-card">
                  <ion-card-title>Treatment</ion-card-title>
                  <p><strong>Patient:</strong> {{ consult.full_name }}</p>
                  <span style="font-size: 15px">{{ consult.mobile }}</span>
                  <rx></rx>
                </div>
              </tab>
              <tab name="Dx">
                <div class="main-card">
                  <ion-card-title>Diagnosis</ion-card-title>
                  <p><strong>Patient:</strong> {{ consult.full_name }}</p>
                  <span style="font-size: 15px">{{ consult.mobile }}</span>
                  <dx></dx>
                </div>
              </tab>
              <tab name="Referral / Disposition">
                <div class="main-card">
                  <ion-card-title>Referal / Disposition</ion-card-title>
                  <p><strong>Patient:</strong> {{ consult.full_name }}</p>
                  <span style="font-size: 15px">{{ consult.mobile }}</span>
                  <referral></referral>
                </div>
              </tab>
              <tab name="Medical Certificate">
                <div class="main-card">
                  <ion-card-title>Medical Certificate</ion-card-title>
                  <p><strong>Patient:</strong> {{ consult.full_name }}</p>
                  <span style="font-size: 15px">{{ consult.mobile }}</span>
                  <medical-certificate></medical-certificate>
                </div>
              </tab>
              <tab name="Invoice">
                <div class="main-card">
                  <ion-card-title>Invoice Details</ion-card-title>
                  <p><strong>Patient:</strong> {{ consult.full_name }}</p>
                  <span style="font-size: 15px">{{ consult.mobile }}</span>
                  <invoice></invoice>
                </div>
              </tab>
              <tab name="Send Script">
                <div class="main-card">
                  <a
                    href=""
                    class="router-button"
                    id="send-script-a"
                    style="
                      font-size: 14px;
                      position: absolute;
                      top: 114px;
                      right: 44px;
                      z-index: 1;
                    "
                    @click.prevent="sendExternalScript()"
                    >Send Script to Patient</a
                  >
                  <a
                    href=""
                    class="router-button"
                    id="end-consult-a"
                    style="
                      font-size: 14px;
                      position: absolute;
                      top: 113px;
                      right: 44px;
                      background: #777777;
                      display: none;
                      z-index: 1;
                    "
                    @click.prevent="endConsult()"
                    >End Consult</a
                  >
                  <ion-card-title>Send Script</ion-card-title>
                  <p><strong>Patient:</strong> {{ consult.full_name }}</p>
                  <span style="font-size: 15px; martgin-bottom: 38px">{{
                    consult.mobile
                  }}</span>
                  <div
                    id="script-check"
                    style="
                      display: none;
                      text-align: left;
                      width: 389px;
                      margin-left: auto;
                      margin-right: auto;
                    "
                  >
                    <hr />
                    <p
                      style="
                        font-weight: bold;
                        font-size: 18px;
                        line-height: 27px;
                        padding-left: 25px;
                        padding-right: 25px;
                      "
                    >
                      Script Sent
                      <ion-icon
                        :icon="checkmarkCircleOutline"
                        style="
                          color: white;
                          background: #bf0000;
                          border-radius: 30px;
                          float: right;
                          font-size: 19px;
                        "
                      />
                    </p>
                    <hr />
                  </div>
                  <send-script></send-script>
                </div>
              </tab>
            </tabs>
          </div>
        </ion-col>
        <ion-col size-md="2" size-lg="2"> </ion-col>
      </ion-row>
    </ion-content>
  </ion-page>
</template>

<script>
import Patient from "@/components/doctor/external-consult/updatePatient";
import Hx from "@/components/doctor/external-consult/hx";
import Rx from "@/components/doctor/external-consult/rx";
import Dx from "@/components/doctor/external-consult/dx";
import Referral from "@/components/doctor/external-consult/referral";
import MedicalCertificate from "@/components/doctor/external-consult/medicalCertificate";
import Invoice from "@/components/doctor/external-consult/invoice";
import SendScript from "@/components/doctor/external-consult/script";

import { IonIcon, IonPage, IonContent, popoverController } from "@ionic/vue";
import {
  ellipsisHorizontalOutline,
  checkmarkCircleOutline,
} from "ionicons/icons";
import { Tabs, Tab } from "vue3-tabs-component";
import Popover from "@/components/menu/popover.vue";

export default {
  name: "ExternalDoctorConsult",
  components: {
    IonIcon,
    IonPage,
    IonContent,
    Tab,
    Tabs,
    Hx,
    Rx,
    Dx,
    Referral,
    MedicalCertificate,
    Invoice,
    SendScript,
    Patient
  },
  data() {
    return {
      payload: {
        consult_id: 0,
        external_consult_id: null,
      },
    };
  },
  setup() {
    return {
      ellipsisHorizontalOutline,
      checkmarkCircleOutline,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    initials() {
      return this.user.first_name.charAt(0) + " " + this.user.last_name;
    },
    consult() {
      return this.$store.state.doctor.externalConsult;
    },
  },
  methods: {
    async openPopover(ev) {
      const popover = await popoverController.create({
        component: Popover,
        cssClass: "my-custom-class",
        event: ev,
        translucent: true,
      });
      await popover.present();
      const { role } = await popover.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    },
    sendExternalScript: function () {
      document.getElementById("send-script-a").style.display = "none";
      document.getElementById("end-consult-a").style.display = "block";
      document.getElementById("script-check").style.display = "block";

      this.$store.dispatch("SendExternalScript", this.consult.id);
    },
    endConsult: function () {
      this.$store.dispatch("EndExternalConsult", this.consult.id);
    },
  },
  mounted() {
    this.payload.external_consult_id = this.consult.id;
    this.$store.dispatch("GetInvoice", this.payload);

    document.getElementById("send-script-a").style.display = "block";
    document.getElementById("end-consult-a").style.display = "none";
    document.getElementById("script-check").style.display = "none";
  },
};
</script>

<style lang="css" scoped>
ion-toolbar ion-icon {
  font-size: 22px;
  float: right;
  padding-right: 10px;
}

ion-card-title {
  color: var(--ion-color-primary);
  margin: 0px !important;
  padding-top: 48px;
}

p {
  font-size: 20px;
  margin-top: 8px;
}

.content {
  margin-left: 10%;
  margin-right: 10%;
}

h1 {
  font-size: 22px !important;
  line-height: 33px !important;
  font-weight: 700 !important;
  color: black;
  padding-top: 21px;
  margin-right: 28px;
}

.consult-card-data {
  font-size: 18px;
  line-height: 27px;
  color: #777777;
}

.consult-link {
  background: #bf0000;
  padding-top: 23px;
  padding-bottom: 23px;
  width: 70px;
  height: 70px;
  border-radius: 35px;
  margin-top: 15px;
  float: right;
  margin-right: 24px;
}

.tabs-component-panel {
  background: white;
}

.main-card {
  margin-bottom: 59px;
  width: 100%;
  margin: 0px;
}

.table-link {
  font-size: 10px;
  line-height: 15px;
  color: #000000;
  text-decoration-line: underline;
  margin-right: 30px;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #000000;
  width: 389px;
}
</style>