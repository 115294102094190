<template>
  <DataTable
    :value="consultsSeen"
    responsiveLayout="scroll"
    :paginator="true"
    :rows="10"
    paginatorTemplate="RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink "
    :rowsPerPageOptions="[5, 10, 15, 25, 50, 75]"
    rowsPerPageTemplate="Rows per page"
    currentPageReportTemplate="{first}-{last} of {totalRecords}"
  >
    <Column header="Date" :sortable="true">
      <template #body="{ data }">
        {{ formatDate(data.created_at) }}
      </template>
    </Column>
    <Column header="Patient" :sortable="true">
      <template #body="{ data }">
        {{ data.patient.first_name.charAt(0) }}.
        {{ data.patient.last_name }}
      </template>
    </Column>
    <Column header="Notes">
      <template #body="{ data }">
        <a
          href=""
          class="table-link"
          @click.prevent="
            openViewNotesModal(data.script.hx, data.script.findings)
          "
          ><img
            src="/assets/icon/view.svg"
            width="15"
            style="
              filter: invert(7%) sepia(97%) saturate(7203%) hue-rotate(5deg)
                brightness(100%) contrast(108%);
            "
          />
          VIEW NOTES</a
        >
        <!-- <a
                      class="table-link"
                      href="#"
                      @click.prevent="openAddNotesModal()"
                      >ADD COMMENTS</a
                    > -->
        <Dialog
          header="ADD COMMENTS"
          v-model:visible="displayAddNotes"
          :style="{ width: '50vw' }"
          :modal="true"
        >
          <form ref="form" @submit.prevent="addNotes" style="padding-top: 20px">
            <ion-row>
              <ion-col size-md="6" size-lg="6">
                <span class="p-float-label">
                  <Textarea
                    :autoResize="true"
                    rows="5"
                    cols="30"
                    style="min-height: 200px"
                    required
                    v-model="data.script.hx"
                  />
                  <label for="hx">History</label>
                </span>
              </ion-col>
              <ion-col size-md="6" size-lg="6">
                <span class="p-float-label">
                  <Textarea
                    :autoResize="true"
                    rows="5"
                    cols="30"
                    style="min-height: 200px"
                    required
                    v-model="data.script.findings"
                  />
                  <label for="findings">Findings</label>
                </span>
              </ion-col>
            </ion-row>

            <!-- add hidden field for id -->
            <ion-button
              size="small"
              type="submit"
              shape="round"
              style="float: right"
              >Save</ion-button
            >
          </form>
        </Dialog>
      </template>
    </Column>

    <Column header="Script">
      <template #body="{ data }">
        <a class="table-link" v-bind:href="data.script_url" target="_blank"
          ><img
            src="/assets/icon/view.svg"
            width="15"
            style="
              filter: invert(7%) sepia(97%) saturate(7203%) hue-rotate(5deg)
                brightness(100%) contrast(108%);
            "
          />
          VIEW SCRIPT</a
        >

        <!-- <a
                      href=""
                      class="table-link"
                      @click.prevent="openScriptModal()"
                      >ADD COMMENTS</a
                    > -->
        <Dialog
          header="ADD COMMENTS"
          v-model:visible="displayAddScript"
          :style="{ width: '50vw' }"
          :modal="true"
        >
          <form ref="form" @submit.prevent="submit">
            <span class="p-float-label" style="margin-top: 29px">
              <InputText
                id="medical_certificate_comments"
                type="text"
                v-model="data.script.medical_certificate_comments"
              />
              <!-- ADD HIDDEN FIELD FOR ID -->
              <label for="medical_certificate_comments"
                >Additional Comments</label
              >
            </span>
          </form>
        </Dialog>

        <a href="" class="table-link" @click.prevent="sendScript(data.id)"
          ><img
            src="/assets/icon/back.svg"
            width="13"
            style="
              transform: rotate(-180deg);
              filter: invert(7%) sepia(97%) saturate(7203%) hue-rotate(5deg)
                brightness(100%) contrast(108%);
            "
          />SEND TO PATIENT</a
        >
      </template>
    </Column>

    <Column header="Investigations/Other">
      <template #body="{ data }">
        <a
          class="table-link"
          href="#"
          @click.prevent="openViewInvestigationsModal()"
          ><img
            src="/assets/icon/view.svg"
            width="15"
            style="
              filter: invert(7%) sepia(97%) saturate(7203%) hue-rotate(5deg)
                brightness(100%) contrast(108%);
            "
          />
          VIEW INVESTIGATIONS</a
        >
        <Dialog
          header="VIEW INVESTIGATIONS"
          v-model:visible="displayViewInvestigations"
          :style="{ width: '50vw' }"
          :modal="true"
        >
          <DataTable
            :value="data.investigations"
            responsiveLayout="scroll"
            :paginator="true"
            :rows="10"
            paginatorTemplate="RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink "
            :rowsPerPageOptions="[5, 10, 15, 25, 50, 75]"
            rowsPerPageTemplate="Rows per page"
            currentPageReportTemplate="{first}-{last} of {totalRecords}"
          >
            <Column header="Date" :sortable="true">
              <template #body="{ data }">
                {{ formatDate(data.created_at) }}
              </template>
            </Column>
            <Column header="Investigations/Other" :sortable="true">
              <template #body="{ data }">
                <a
                  class="table-link"
                  v-bind:href="data.document"
                  target="_blank"
                  ><img
                    src="/assets/icon/view.svg"
                    width="15"
                    style="
                      filter: invert(7%) sepia(97%) saturate(7203%)
                        hue-rotate(5deg) brightness(100%) contrast(108%);
                    "
                  />
                  {{ data.name }}</a
                >
              </template>
            </Column>
          </DataTable>
        </Dialog>

        <!-- <a
                      class="table-link"
                      href=""
                      @click.prevent="openAddInvestigationsModal(data.consult_id)"
                      ><img
                        src="/assets/icon/download.svg"
                        width="9"
                        style="
                          transform: rotate(-180deg);
                          filter: invert(7%) sepia(97%) saturate(7203%)
                            hue-rotate(5deg) brightness(100%) contrast(108%);
                        "
                      />UPLOAD INVESTIGATIONS</a
                    > -->
        <Dialog
          header="UPLOAD INVESTIGATIONS"
          v-model:visible="displayAddInvestigations"
          :style="{ width: '50vw' }"
          :modal="true"
        >
          <form ref="form" @submit.prevent="uploadInvestigations(data.user_id)">
            <span class="p-float-label">
              <InputText id="name" type="text" v-model="form3.name" required />
              <label for="name">Name of document</label>
            </span>

            <div class="document-label">
              <label for="document" class="document-label">Add document</label>
            </div>

            <div id="drop-area" style="text-align: center">
              <div id="upload">
                <input
                  type="file"
                  id="fileElem"
                  accept="image/png, image/jpeg, application/pdf"
                  @change="handleFilesSelected"
                />
                <label class="drop-button" for="fileElem"
                  >Select from device</label
                >
              </div>

              <div id="clear">
                <p id="file" class="drag-text"></p>
                <label class="drop-button" @click="clear()">Clear</label>
              </div>
            </div>

            <ion-button size="small" shape="round" type="submit"
              >Add</ion-button
            >
          </form>
        </Dialog>
      </template>
    </Column>
  </DataTable>

  <!-- View Notes Dialog -->
  <Dialog
    header="VIEW NOTES"
    v-model:visible="displayViewNotes"
    :style="{ width: '50vw' }"
    :modal="true"
  >
    <ion-row style="padding-top: 20px">
      <ion-col size-md="6" size-lg="6">
        <span class="p-float-label">
          <Textarea
            :autoResize="true"
            rows="5"
            cols="30"
            style="min-height: 200px"
            disabled
            v-model="notes.hx"
          />
          <label for="hx">History</label>
        </span>
      </ion-col>
      <ion-col size-md="6" size-lg="6">
        <span class="p-float-label">
          <Textarea
            :autoResize="true"
            rows="5"
            cols="30"
            style="min-height: 200px"
            disabled
            v-model="notes.findings"
          />
          <label for="findings">Findings</label>
        </span>
      </ion-col>
    </ion-row>
  </Dialog>
</template>

<script>
import { alertController } from "@ionic/vue";

export default {
  components: {},
  data() {
    return {
      form1: {},
      form2: {
        medical_certificate_comments: null,
      },
      form3: {
        user_id: null,
        name: null,
        document: null,
      },
      displayViewNotes: false,
      // displayAddNotes: false,
      // displayAddScript: false,
      displayViewInvestigations: false,
      // displayAddInvestigations: false,
      serverOutput: [],
      successes: [],
      errors: [],
      notes: {
        hx: "",
        findings: "",
      },
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    consultsSeen() {
      return this.$store.state.doctor.consultsSeen;
    },
  },
  created() {},
  methods: {
    formatDate(value) {
      var d = new Date(value),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    openViewNotesModal(hx, findings) {
      this.notes.hx = hx;
      this.notes.findings = findings;
      this.displayViewNotes = true;
    },
    closeViewNotesModal() {
      this.notes.hx = "";
      this.notes.findings = "";
      this.displayViewNotes = false;
    },
    // openAddNotesModal() {
    //   this.displayAddNotes = true;
    // },
    // closeAddNotesModal() {
    //   this.displayAddNotes = false;
    // },
    addNotes: function () {
      this.$store.dispatch("UpdateScriptData");
    },
    // openScriptModal() {
    //   this.displayAddScript = true;
    // },
    // closeScriptModal() {
    //   this.displayAddScript = false;
    // },
    openViewInvestigationsModal() {
      this.displayViewInvestigations = true;
    },
    closeViewInvestigationsModal() {
      this.displayViewInvestigations = false;
    },
    // openAddInvestigationsModal() {
    //   this.displayAddInvestigations = true;
    // },
    // closeAddInvestigationsModal() {
    //   this.displayAddInvestigations = false;
    // },
    addScriptComments: function () {},
    async sendScript(id) {
      this.$store.dispatch("SendScript", id).then();
      const alert = await alertController.create({
        message: `<p style="font-size: 11px;line-height: 16.5px;text-align: center;color: #343434;margin: 0px" >Script sent successfully </p>`,
        buttons: [
          {
            text: "Close",
            role: "cancel",
            handler: () => {
              console.log("cancel");
            },
          },
        ],
      });

      await alert.present();
    },
    clear: function () {
      document.getElementById("clear").style.display = "none";
      document.getElementById("upload").style.display = "block";
    },
    handleFilesSelected: function (event) {
      let target = event.target;
      let file = event.target.files[0];
      this.uploadFile(file, target);
    },
    uploadFile: async function (file, target) {
      this.form3.document = await this.toBase64(file);

      target.parentElement.style.display = "none";
      target.parentElement.nextSibling.style.display = "block";
      target.parentElement.nextSibling.firstElementChild.textContent =
        file["name"];
    },
    async toBase64(file) {
      return await new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    uploadInvestigations: function (id) {
      this.form3.user_id = id;
      this.$store.dispatch("UploadDocument", this.form3);
      this.closeAddInvestigationsModal();
    },
  },
};
</script>

<style scoped src="../../../../theme/drag&drop.css">
</style>
<style scoped>
p {
  font-size: 20px;
  margin-top: 8px;
}

.content {
  margin-left: 10%;
  margin-right: 10%;
}

h1 {
  font-size: 22px !important;
  line-height: 33px !important;
  font-weight: 700 !important;
  color: black;
  padding-top: 21px;
  margin-right: 28px;
}

hr {
  position: absolute;
  width: 76px;
  height: 0px;
  left: 75%;
  top: 46px;
  border: 1px solid #777777;
  transform: rotate(90deg);
}

.table-link {
  font-size: 10px;
  line-height: 15px;
  color: #000000;
  text-decoration-line: underline;
  margin-right: 30px;
}

@media only screen and (max-width: 1450px) {
  h1 {
    font-size: 20px !important;
  }

  span {
    font-size: 15px !important;
  }

  .hr-2 {
    left: -58px !important;
  }

  hr {
    top: 39px !important;
  }
}
</style>