<template>
  <ion-page>
  <ion-content style="text-align: center">
      <img src="/assets/icon/WeDoc_Logo_Standalone_Portrait.svg" height="153" />

      <ion-card-header>
        <ion-card-title style="margin-top: 31.87px; margin-bottom: 27px"
          >Email Verification</ion-card-title
        >
      </ion-card-header>

      <ion-card-content>
          <div v-if="errors.length > 0" style="background: var(--ion-color-secondary-tint);padding: 10px;border-radius: 9px;margin-bottom: 50px;">
            <h2 v-for="error in errors" :key="error.indexOf(error)" style="text-align: left;">{{ error }}</h2>
          </div>

          <div v-if="successes.length > 0" style="padding: 10px;border-radius: 9px;margin-bottom: 50px;">
            <h2 v-for="success in successes" :key="success.indexOf(success)" style="text-align: left;">{{ success }}</h2>
          </div>
        <hr />

        <div class="auth-links">
          <a href="/login" style="float: left"> Login </a>
          <a href="/register" style="float: right"> Register </a>
        </div>
      </ion-card-content>
  </ion-content>
  </ion-page>
</template>


<script>
import {
  IonCardTitle,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonPage
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Email Verification",
  components: {
    IonCardTitle,
    IonCardContent,
    IonCardHeader,
    IonContent,
    IonPage
  },
  data() {
    return {
      token: null,
      errors: [],
      successes: []
    };
  },
  async ionViewDidEnter(){
    this.token = decodeURIComponent(this.$route.params.token);
    await this.$store.dispatch("emailVerification", this.token).then(
        res=>{
          this.successes.push(res.response);
        }
      ).catch(
        error=>{
          this.errors.push(error.error);
        }
      );
  },
});
</script>

<style scoped src="../../theme/auth.css">
</style>
<style scoped>
    ion-button {
    width: auto;
    }
</style>