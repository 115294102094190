import axios from "axios";
let base_url = "https://api.wedoc.co.za";

const actions = {
    applyPromocode: async (_, payload) => {
        await axios
            .get(base_url + "/sanctum/csrf-cookie")
            .then((res) => console.log(res))
            .catch((err) => console.error(err));
        return new Promise((resolve, reject) => {
            axios
                .get(base_url + "/api/applyPromoCode/" + payload.id)
                .then(
                    (res) => {
                        console.log(res);
                        if (res.data) {
                            console.log(res.data);
                            resolve({ response: res.data });
                        } else {
                            reject({ error: res.data });
                        }
                    },
                    (error) => {
                        console.log("Failed ", error);
                        reject({ error: error.response.data });
                    }
                );
        });
    },
    UpdatePaidInvoice: async (_, payload) => {
        await axios
            .get(base_url + "/sanctum/csrf-cookie")
            .then((res) => console.log(res))
            .catch((err) => console.error(err));
        await axios
            .get(base_url + "/api/updatePaidInvoice/" + payload.id)
            .then(
                (res) => {
                    console.log(res);
                    if (res.data) {
                        console.log(res.data);
                    }
                },
                (error) => {
                    console.log("Failed ", error);
                }
            );
    }
};

export default {
    actions,
};

