<template>
  <ion-page>
    <ion-content style="--background: var(--ion-color-light)">
      <ion-header>
        <ion-toolbar>
          <ion-title>Oxygen Saturation</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-card class="main-card">
        <ion-card-header>
          <router-link :to="{ path: `/doctor/consult/` }" class="logo"><img src="assets/icon/back.svg" width="26" style="float: left"
          /></router-link>

          <div class="header" style="margin-top: 47px">
            <h5>Oxygen Saturation</h5>
          </div>
        </ion-card-header>

        <ion-card-content>
          <DataTable
            :value="vitals.vitals.oxygen_saturations"
            responsiveLayout="scroll"
            :paginator="true"
            :rows="10"
            paginatorTemplate="RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink "
            :rowsPerPageOptions="[5, 10, 15, 25, 50, 75]"
            rowsPerPageTemplate="Rows per page"
            currentPageReportTemplate="{first}-{last} of {totalRecords}"
          >
            <Column field="created_at" header="Date" :sortable="true">
              <template #body="{ data }">
                {{ formatDate(data.created_at) }}
              </template>
            </Column>
            <Column field="created_at" header="Time" :sortable="true">
              <template #body="{ data }">
                {{ formatTime(data.created_at) }}
              </template>
            </Column>
            <Column field="value" header="Oxygen Saturation" :sortable="true">
                <template #body="{ data }">
                   {{ data.value}}%
                </template>
            </Column>
            <Column>
             <template #body="{ data }">
                <img @click.prevent="deleteVital(data.id)" src="/assets/icon/close-app.svg" width="18" style="filter: invert(11%) sepia(98%) saturate(4429%) hue-rotate(0deg) brightness(88%) contrast(118%);"/>
              </template>
            </Column>
          </DataTable>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonCard,
  IonCardContent,
  IonCardHeader,
} from "@ionic/vue";

export default {
  name: "Oxygen Saturation",
  components: {
    IonContent,
    IonCardHeader,
    IonCardContent,
    IonCard,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonPage,
  },
  data() {
    return {
      form: {
        id: null,
        user_id: null,
      },
    };
  },
  computed: {
    vitals() {
      return this.$store.state.vitals;
    },
    user() {
      return this.$store.state.user;
    },
  },
  ionViewDidEnter() {
    this.form.user_id = this.user.id;
    this.$store.dispatch("Vitals", this.user.id).then();
  },
  methods: {
    formatDate(value) {
      var d = new Date(value),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    formatTime(value) {
      var d = new Date(value),
        hours = "" + (d.getHours() + 1),
        minutes = "" + d.getMinutes();

      if (hours.length < 2) hours = "0" + hours;
      if (minutes.length < 2) minutes = "0" + minutes;

      return [hours, minutes].join(":");
    },
    deleteVital(id){
      this.form.id = id;
      this.$store.dispatch("DeleteVital", this.form);
    }
  },
};
</script>

<style scoped>
h5 {
  color: black;
  line-height: 27px;
  font-weight: 700;
  text-align: left;
}

.main-card {
  margin-top: 12px;
}

ion-button {
  float: right;
}
</style>