import { createRouter, createWebHistory } from '@ionic/vue-router';

// Auth
import Login from '../views/Auth/Login.vue';
import LostPassword from '../views/Auth/LostPassword.vue';
import ResetPassword from '../views/Auth/ResetPassword.vue';
import Register from '../views/Auth/Register.vue';
import TermsAndConditions from '../views/Auth/TermsAndConditions.vue';
import EmailVerification from '../views/Auth/EmailVerification.vue';
import VerifyEmail from '../views/Auth/Verify.vue';

// External Patient
import ExternalPatientDetails from '../views/ExternalPatients/PatientDetails.vue';
import PayNow from '../views/ExternalPatients/PayNow.vue';
import PayNowComplete from '../views/ExternalPatients/PayNowComplete.vue';

// Member
import Tabs from '../views/layouts/Member.vue';
import HeartRate from '../views/Member/Vitals/HeartRate.vue';
import BloodPressure from '../views/Member/Vitals/BloodPressure.vue';
import Temperature from '../views/Member/Vitals/Temperature.vue';
import BloodGlucose from '../views/Member/Vitals/BloodGlucose.vue';
import OxygenSaturation from '../views/Member/Vitals/OxygenSaturation.vue';

// Doctor
import DoctorRegister from '../views/Auth/DoctorRegister.vue';
import DoctorDashboard from '../views/Doctor/Dashboard.vue';
import DoctorProfile from '../views/Doctor/Profile.vue';
import ExternalConsult from '../views/Doctor/External/Consult.vue';
import ExternalConsultScript from '../views/Doctor/External/Script.vue';
import DoctorConsult from '../views/Doctor/Consult.vue';
import HeartRateDoctor from '../views/Doctor/Vitals/HeartRate.vue';
import BloodPressureDoctor from '../views/Doctor/Vitals/BloodPressure.vue';
import TemperatureDoctor from '../views/Doctor/Vitals/Temperature.vue';
import BloodGlucoseDoctor from '../views/Doctor/Vitals/BloodGlucose.vue';
import OxygenSaturationDoctor from '../views/Doctor/Vitals/OxygenSaturation.vue';
import VitalsInvestigation from '../views/Doctor/Vitals/Upload.vue';
import VitalsInvestigationUpload from '../views/Doctor/Vitals/AddDocument.vue';

const routes = [
  // AUTH
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: Login
  },
  {
    path:'/verify-email',
    component: VerifyEmail
  },
  {
    path: '/lost-password',
    component: LostPassword
  },
  {
    path: '/reset-password/:token/:email',
    component: ResetPassword
  },
  {
    path: '/register',
    component: Register
  },
  {
    path: '/terms-and-conditions',
    component: TermsAndConditions
  },
  {
    path: '/email-verification/:token',
    component: EmailVerification
  },
  {
    path: '/doctor-register',
    component: DoctorRegister
  },

  // EXTERNAL PATIENTS
  {
    path: '/patient/details',
    component: ExternalPatientDetails
  },
  {
    path: '/patient/pay-now',
    component: PayNow
  },
  {
    path: '/patient/pay-now/complete',
    component: PayNowComplete
  },
  
  // Member
  {
    path: '/member/',
    component: Tabs,
    children: [
      {
        path: '',
        redirect: 'dashboard'
      },
      {
        path: 'dashboard',
        component: () => import('@/views/Member/Dashboard.vue')
      },
      {
        path: 'profile',
        component: () => import('@/views/Member/Profile.vue')
      },
      {
        path: 'medicalhistory',
        component: () => import('@/views/Member/MedicalHistory.vue')
      },
      {
        path: 'medicalhistory/addchronic',
        component: () => import('@/views/Member/MedicalHistory/AddChronic.vue')
      },
      {
        path: 'medicalhistory/addhospitaladmission',
        component: () => import('@/views/Member/MedicalHistory/AddHospitalAdmission.vue')
      },
      {
        path: 'medicalhistory/addillness',
        component: () => import('@/views/Member/MedicalHistory/AddIllness.vue')
      },
      {
        path: 'medicalhistory/addsurgery',
        component: () => import('@/views/Member/MedicalHistory/AddSurgery.vue')
      },
      {
        path: 'medicalhistory/addvaccination',
        component: () => import('@/views/Member/MedicalHistory/AddVaccination.vue')
      },
      {
        path: 'vitals',
        component: () => import('@/views/Member/Vitals.vue'),
      },
      {
        path: 'vitals/heartrate',
        component: HeartRate
      },
      {
        path: 'vitals/bloodpressure',
        component: BloodPressure
      },
      {
        path: 'vitals/temperature',
        component: Temperature
      },
      {
        path: 'vitals/bloodglucose',
        component: BloodGlucose
      },
      {
        path: 'vitals/oxygensaturation',
        component: OxygenSaturation
      },
      {
        path: 'vitals/upload',
        component: () => import('@/views/Member/Vitals/Upload.vue')
      },
      {
        path: 'vitals/upload/add',
        component: () => import('@/views/Member/Vitals/AddDocument.vue')
      },
      {
        path: "vitals/add",
        component: () => import('@/views/Member/Vitals/Add.vue')
      },
      {
        path: 'consult',
        component: () => import('@/views/Member/Consult.vue')
      },
      {
        path: 'consult/complaint',
        component: () => import('@/views/Member/Consult/Complaint.vue')
      },
      {
        path: 'consult/waitingroom',
        component: () => import('@/views/Member/Consult/WaitingRoom.vue')
      },
      {
        path: 'consult/medicalcertificate',
        component: () => import('@/views/Member/Consult/MedicalCertificate.vue')
      },
      {
        path: 'previousconsults',
        component: () => import('@/views/Member/PreviousConsults.vue')
      },
      {
        path: 'notifications',
        component: () => import('@/views/Member/Notifications.vue')
      },
    ]
  },

  // DOCTOR
  {
    path: '/doctor',
    redirect: '/doctor/dashboard'
  },
  {
    path: '/doctor/dashboard',
    component: DoctorDashboard,
  },
  {
    path: '/doctor/profile',
    component: DoctorProfile
  },
  {
    path: '/doctor/externalconsult',
    component: ExternalConsult
  },
  {
    path: '/doctor/externalconsult/script',
    component: ExternalConsultScript
  },
  {
    path: '/doctor/consult',
    component: DoctorConsult
  },
  {
    path: '/doctor/vitals/heartrate',
    component: HeartRateDoctor
  },
  {
    path: '/doctor/vitals/bloodpressure',
    component: BloodPressureDoctor
  },
  {
    path: '/doctor/vitals/temperature',
    component: TemperatureDoctor
  },
  {
    path: '/doctor/vitals/bloodglucose',
    component: BloodGlucoseDoctor
  },
  {
    path: '/doctor/vitals/oxygensaturation',
    component: OxygenSaturationDoctor
  },
  {
    path: '/doctor/vitals/upload',
    component: VitalsInvestigation
  },
  {
    path: '/doctor/vitals/upload/add',
    component: VitalsInvestigationUpload
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
