<template>
  <ion-page>
    <ion-toolbar class="main-header">
      <router-link :to="{ path: `/doctor/dashboard` }" class="logo"
        ><img
          src="/assets/icon/WeDoc_Logo_Standalone_Landscape.svg"
          height="26"
      /></router-link>

     <ion-button @click="openPopover" style="float: right;border-radius: 30px;width: auto;">
        <ion-icon :icon="ellipsisHorizontalOutline"/>
      </ion-button>

      <span style="float: right; padding-right: 30px"
        >Welcome back, <strong>Dr. {{ initials }}</strong>
      </span>
    </ion-toolbar>

    <ion-content
      style="--background: var(--ion-color-light); align-items: center"
    >
      <ion-row>
        <ion-col size-md="7" size-lg="7">
          <div class="tabs-component">
            <tabs :options="{ useUrlFragment: false }">
              <!-- <tab name="Medical History">
                <div class="main-card">
                  <ion-card-header>
                    <ion-card-title>Medical History</ion-card-title>
                    <p>
                      <strong>Patient:</strong> {{ patient.first_name }}
                      {{ patient.last_name }}
                    </p>
                  </ion-card-header>
                  <medical-history></medical-history>
                </div>
              </tab> -->
              <tab name="Vitals">
                <div class="main-card">
                  <ion-card-title>Vitals</ion-card-title>
                  <p>
                    <strong>Patient:</strong> {{ patient.first_name }}
                    {{ patient.last_name }}
                    <br>
                    <span style="font-size: 15px;">{{consult.data.mobile}}</span>
                  </p>
                </div>

                <vitals></vitals>
              </tab>
              <tab name="Hx and Findings">
                <div class="main-card">
                  <ion-card-title>Hx and Findings</ion-card-title>
                  <p>
                    <strong>Patient:</strong> {{ patient.first_name }}
                    {{ patient.last_name }} 
                    <br>
                    <span style="font-size: 15px;">{{consult.data.mobile}}</span>
                  </p>
                  <hx></hx>
                </div>
              </tab>
              <tab name="Rx">
                <div class="main-card">
                  <ion-card-title>Treatment</ion-card-title>
                  <p>
                    <strong>Patient:</strong> {{ patient.first_name }}
                    {{ patient.last_name }} 
                    <br>
                    <span style="font-size: 15px;">{{consult.data.mobile}}</span>
                  </p>
                  <rx></rx>
                </div>
              </tab>
              <tab name="Dx">
                <div class="main-card">
                  <ion-card-title>Diagnosis</ion-card-title>
                  <p>
                    <strong>Patient:</strong> {{ patient.first_name }}
                    {{ patient.last_name }} 
                    <br>
                    <span style="font-size: 15px;">{{consult.data.mobile}}</span>
                  </p>
                  <dx></dx>
                </div>
              </tab>
              <tab name="Referral / Disposition">
                <div class="main-card">
                  <ion-card-title>Referal / Disposition</ion-card-title>
                  <p>
                    <strong>Patient:</strong> {{ patient.first_name }}
                    {{ patient.last_name }} 
                    <br>
                    <span style="font-size: 15px;">{{consult.data.mobile}}</span>
                  </p>
                  <referral></referral>
                </div>
              </tab>
              <tab name="Medical Certificate">
                <div class="main-card">
                  <ion-card-title>Medical Certificate</ion-card-title>
                  <p>
                    <strong>Patient:</strong> {{ patient.first_name }}
                    {{ patient.last_name }}
                    <br>
                    <span style="font-size: 15px;">{{consult.data.mobile}}</span>
                  </p>
                  <medical-certificate></medical-certificate>
                </div>
              </tab>
              <tab name="Send Script">
                <div class="main-card">
                  <a
                    href=""
                    class="router-button"
                    id="send-script-a"
                    style="font-size: 14px;position: absolute;top: 114px;right: 44px;z-index: 1"
                    @click.prevent="sendMedicalCertificate()"
                    >Send Script to Patient</a
                  >
                  <a
                    href=""
                    class="router-button"
                    id="end-consult-a"
                    style="font-size: 14px;position: absolute;top: 113px;right: 44px;background:#777777; display:none;z-index: 1"
                    @click.prevent="endConsult()"
                    >End Consult</a
                  >
                  <ion-card-title>Send Script</ion-card-title>
                  <p style="martgin-bottom: 38px;">
                    <strong>Patient:</strong> {{ patient.first_name }}
                    {{ patient.last_name }} 
                    <br>
                    <span style="font-size: 15px;">{{consult.data.mobile}}</span>
                  </p>
                  <div id="script-check" style="display: none; text-align:left;width: 389px;margin-left: auto;margin-right: auto;">
                    <hr>
                      <p style="font-weight: bold;font-size: 18px;line-height: 27px;padding-left: 25px;padding-right: 25px;">Script Sent <ion-icon :icon="checkmarkCircleOutline" style="color: white; background: #BF0000;border-radius: 30px;float: right;font-size: 19px;"/></p>
                      <p style="font-weight: bold;font-size: 18px;line-height: 27px;padding-left: 25px;padding-right: 25px;">Script certified by patient <ion-icon  id="certified" :icon="checkmarkCircleOutline" style="color:#777777;float: right;font-size: 19px;"></ion-icon></p>
                      <a style="font-size: 13px;line-height: 19px;padding-left: 25px;padding-right: 25px;" href="" @click.prevent="completeScript()" id="complete-without">Complete Script without certification</a>
                    <hr>
                  </div>
                  <send-script></send-script>
                </div>
              </tab>
            </tabs>
          </div>
        </ion-col>
        <ion-col size-md="5" size-lg="5">
          <div class="zoom">
            <div id="videoSDK" style="height: 100%; background: #e5e5e5;"></div>
            <!-- <div id="callEnded" style="height: 100%; background: #e5e5e5;text-align: center;
                padding-top: 50%;display:none;">
                <p>Call Ended</p>    
            </div> -->
          </div>
        </ion-col>
      </ion-row>
    </ion-content>
  </ion-page>
</template>

<script>
// import MedicalHistory from "@/components/doctor/consult/medicalHistory";
import Vitals from "@/components/doctor/consult/vitals";
import Hx from "@/components/doctor/consult/hx";
import Rx from "@/components/doctor/consult/rx";
import Dx from "@/components/doctor/consult/dx";
import Referral from "@/components/doctor/consult/referral";
import MedicalCertificate from "@/components/doctor/consult/medicalCertificate";
import SendScript from "@/components/doctor/consult/script";

import { IonIcon, IonPage, IonContent, popoverController } from "@ionic/vue";
import { ellipsisHorizontalOutline, checkmarkCircleOutline } from "ionicons/icons";
import { Tabs, Tab } from "vue3-tabs-component";
import Popover from '@/components/menu/popover.vue'

import { VideoSDKMeeting } from "@videosdk.live/rtc-js-prebuilt";
const meeting = new VideoSDKMeeting();

export default {
  name: "DoctorConsult",
  components: {
    IonIcon,
    IonPage,
    IonContent,
    Tab,
    Tabs,
    // MedicalHistory,
    Vitals,
    Hx,
    Rx,
    Dx,
    Referral,
    MedicalCertificate,
    SendScript,
  },
  setup() {
    return {
      ellipsisHorizontalOutline,
      checkmarkCircleOutline
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    initials() {
      return this.user.first_name.charAt(0) + " " + this.user.last_name;
    },
    consultsWaiting() {
      return this.$store.state.doctor.consultsWaiting;
    },
    consultsSeen() {
      return this.$store.state.doctor.consultsSeen;
    },
    patient() {
      return this.$store.state.doctor.currentConsult.patient;
    },
    consult() {
      return this.$store.state.doctor.currentConsult;
    },
  },
  ionViewWillEnter() {
    console.log(this.consult);
    if(this.consult.meeting.meeting_id){
      meeting.init({
        name: "Dr." + this.initials,
        apiKey: "3cec4a30-52a7-4ea2-8e02-1c1603b414fb", // generated from app.videosdk.live
        meetingId: this.consult.meeting.meeting_id,

        containerId: "videoSDK",

        micEnabled: true,
        webcamEnabled: true,
        participantCanToggleSelfWebcam: true,
        participantCanToggleSelfMic: true,

        chatEnabled: true,
        screenShareEnabled: false,
        pollEnabled: false,
        whiteBoardEnabled: false,
        raiseHandEnabled: true,

        recordingEnabled: false,

        brandingEnabled: true,
        brandLogoURL: "http://localhost:8100/assets/icon/WeDoc_Logo_Standalone_Landscape.svg",
        brandName: "WeDoc",
        poweredBy: false,

        livestream: {
          autoStart: false,
        },

        permissions: {
          askToJoin: false, // Ask joined participants for entry in meeting
          toggleParticipantMic: false, // Can toggle other participant's mic
          toggleParticipantWebcam: false, // Can toggle other participant's webcam
        },

        joinScreen: {
          visible: true, // Show the join screen ?
          title: "Start Video Consult" 
        },
      });
    }
  },
  methods: {
    async openPopover(ev){
      const popover = await popoverController
      .create({
        component: Popover,
        cssClass: 'my-custom-class',
        event: ev,
        translucent: true
      })
      await popover.present();

       const { role } = await popover.onDidDismiss();
      console.log('onDidDismiss resolved with role', role);
    },
    age(dob) {
      let currentDate = new Date();
      let birthDate = new Date(dob);
      let difference = currentDate - birthDate;
      let age = Math.floor(difference / 31557600000);
      return age;
    },
    timer(created) {
      let currentDateTime = new Date();
      let startDateTime = new Date(created);
      let difference = currentDateTime - startDateTime;
      let time = new Date(difference);
      let days = time.getDate()-1;
      let hours = time.getHours()-2;

      if(days > 0){
        let timer = days + "day " + hours + "hour " + time.getMinutes() + "min " + time.getSeconds() + "sec";
        return timer;
      }else if(hours > 0){
        let timer = hours + "hour " + time.getMinutes() + "min " + time.getSeconds() + "sec";
        return timer;
      }
      
      let timer = time.getMinutes() + "min " + time.getSeconds() + "sec";
      return timer;
    },
    formatDate(value) {
      var d = new Date(value),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    sendMedicalCertificate: function () {
      document.getElementById('send-script-a').style.display = "none";
      document.getElementById('end-consult-a').style.display = "block";
      document.getElementById('script-check').style.display = "block";
      
      // document.getElementById('videoSDK').style.display = "none";
      // document.getElementById('callEnded').style.display = "block";
      this.$store.dispatch("SendMedicalCertificate", this.consult.meeting.meeting_id);
    },
    endConsult: function () {
      this.$store.dispatch("EndConsult", this.consult.id);
    },
    completeScript: function() {
      this.$store.dispatch("completeScript", this.patient.id);
      document.getElementById('complete-without').style.display = "none";
    },
  },
  mounted() {
    // document.getElementById('callEnded').style.display = "none";
    document.getElementById('videoSDK').style.display = "block";

    document.getElementById('send-script-a').style.display = "block";
    document.getElementById('end-consult-a').style.display = "none";
    document.getElementById('script-check').style.display = "none";

    window.Echo.channel(this.user.id);
    window.Echo.connector.socket.on("App\\Events\\AcceptMedicalCertificate", (data) => {
      console.log(data);
      document.getElementById('certified').style = 'color: white; background: #BF0000;border-radius: 30px;float: right;font-size: 19px;';

      document.getElementById('complete-without').style.display = "none";
    });
  }
};
</script>

<style lang="css" scoped>
ion-toolbar ion-icon {
  font-size: 22px;
  float: right;
  padding-right: 10px;
}

ion-card-title {
  color: var(--ion-color-primary);
  margin: 0px !important;
  padding-top: 48px;
}

p {
  font-size: 20px;
  margin-top: 8px;
}

.content {
  margin-left: 10%;
  margin-right: 10%;
}

h1 {
  font-size: 22px !important;
  line-height: 33px !important;
  font-weight: 700 !important;
  color: black;
  padding-top: 21px;
  margin-right: 28px;
}

.consult-card-data {
  font-size: 18px;
  line-height: 27px;
  color: #777777;
}

.consult-link {
  background: #bf0000;
  padding-top: 23px;
  padding-bottom: 23px;
  width: 70px;
  height: 70px;
  border-radius: 35px;
  margin-top: 15px;
  float: right;
  margin-right: 24px;
}

.tabs-component-panel {
  background: white;
}

.main-card {
  margin-bottom: 59px;
  width: 100%;
  margin: 0px;
}

.table-link {
  font-size: 10px;
  line-height: 15px;
  color: #000000;
  text-decoration-line: underline;
  margin-right: 30px;
}

.zoom {
  background: white;
  height: 800px;
  margin-top: 42px;
  margin-right: 40px;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.102);
  border-radius: 5px 5px 0px 0px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 37px;
  padding-left: 37px;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #000000;
  width: 389px;
}
</style>