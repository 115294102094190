import router from "../../router";
import axios from "axios";
let base_url = "https://api.wedoc.co.za";

const state = {
  medicalHistory: [],
};

const getters = {
  getHistory: ({ medicalHistory }) => {
    return medicalHistory;
  },
};
const mutations = {
  STORE_MEDICAL_HISTORY: (state, payload) => {
    state.medicalHistory = payload;
  },
};
const actions = {
  MedicalHistory: async ({ commit }, payload) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    await axios.get(base_url + "/api/getMedicalHistory/" + payload).then(
      (res) => {
        console.log(res.data);
        commit("STORE_MEDICAL_HISTORY", res.data);
      },
      (error) => {
        console.log("Failed ", error);
      }
    );
  },
  AddMedicalHistory: async ( _ , payload) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    await axios.post(base_url + "/api/addMedicalHistory", {
      // allergies
      medicine: payload.medicine,
      // chronic
      name: payload.name,
      dosage: payload.dosage,
      frequency: payload.frequency,
      start_date: payload.start_date,
      // hospital
      date: payload.date,
      reason: payload.reason,
      length: payload.length,
      // general
      user_id: payload.user_id,
      category: payload.category,
    }).then(
      (res) => {
        console.log(res);
        router.replace("/member/medicalhistory"); 
      },
      (error) => {
        console.log("Failed ", error);
      }
    );
  },
  DeleteMedicalHistory: async ( _ , payload) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    await axios.post(base_url + "/api/deleteMedicalHistory", {
      id: payload.id,
      category: payload.selected,
      user_id: payload.user_id
    }).then(
      (res) => {
        console.log(res);
      },
      (error) => {
        console.log("Failed ", error);
      }
    );
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
