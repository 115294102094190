import axios from "axios";
let base_url = "https://api.wedoc.co.za";

const state = {
  invoice: [],
};

const mutations = {
  STORE_INVOICE: (state, payload) => {
    state.invoice = payload;
  },
};

const actions = {
  CreateInvoice: async ({ commit }, payload) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    return new Promise((resolve, reject) => {
      axios
        .post(base_url + "/api/consult/invoice", {
          type: payload.type,
          consult_id: payload.consult_id,
          paid: payload.paid,
          consult_type: payload.consult_type,
          promo_percentage: payload.promo_percentage,
          promo_name: payload.promo_name,
        })
        .then(
          (res) => {
            console.log(res.data);

            commit("STORE_INVOICE", res.data);
            resolve({ response: "Successfully saved" });
          },
          (error) => {
            console.log("Failed ", error);
            reject({ error: "Error in creating an invoice" });
          }
        );
    });
  },
  GetInvoice: async ({ commit }, payload) => {
    await axios
      .get(base_url + "/sanctum/csrf-cookie")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    await axios
      .get(
        base_url +
          "/api/consult/invoice/" +
          payload.consult_id +
          "/" +
          payload.external_consult_id
      )
      .then(
        (res) => {
          console.log(res);
          if (res.data) {
            console.log(res.data);
            commit("STORE_INVOICE", res.data);
          }
        },
        (error) => {
          console.log("Failed ", error);
        }
      );
  },
};

export default {
  state,
  mutations,
  actions,
};
